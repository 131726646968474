import mapperIcon from '../../utils/mappers/mapper-icon'

/**
 * Module usp mapper
 * @param {Object} data - content data
 * @return {Object} - normalized data
 */
export const mapperModuleUsp = (data: any) => {
  if (!data) {
    return null
  }

  return {
    contentType: data.contentType || '',
    id: data.sys?.id || '',
    title: data.title || '',
    description: data.description || null, // RichText
    icon: mapperIcon(data.icon)
  }
}
