import { defu } from 'defu'

// Always add 'min' and 'navigation' categories
const careersDataCategories = new Map([
  ['PageCareers', ['careers']],
  ['PageCareersLocation', ['careers']],
  ['PageCareersLocationsDetail', ['careers']],
  ['PageCareersFormula', ['careers']],
  ['PageCareersVacancies', ['careers', 'filter-labels']],
  ['PageCareersVacanciesDetail', ['careers']],
  ['PageCareersFaqs', ['careers']],
  ['PageCareersCareerTypesDetail', ['careers', 'filter-labels']],
  ['PageCareersDei', ['careers']]

])

const careersApiEndpoints = new Map([[
  'careers', '/api/data/careers'
]])

export const useCareersGlobalData = () => {
  const { labels, globalContentData, localeGlobalContentData, setGlobalContentData, generateDataPromisesForContentType } = useGlobalData()

  async function fetchGlobalDataForContentType (contentType) {
    const promises = generateDataPromisesForContentType(contentType, careersDataCategories, careersApiEndpoints)
    const response = await Promise.all(promises)

    let data = {}
    response.forEach((r: any) => {
      data = defu(r, data)
    })

    setGlobalContentData(data)
  }

  return {
    labels,
    globalContentData,
    localeGlobalContentData,
    fetchGlobalDataForContentType
  }
}
