/**
 * Converts a hex color code to an RGBA color code.
 * @param {String} hex - The hex color code.
 * @param {Number} opacity - The opacity value between 0 and 1.
 * @returns {String|null} The RGBA color code or null if invalid input.
 *
 * @example
 * hexToRgba('#ffffff', 0.5) // 'rgba(255,255,255,0.5)'
 */
export const hexToRgba = (hex: string, opacity: number = 1) => {
  if (typeof opacity !== 'number' || opacity < 0 || opacity > 1) {
    return null // invalid opacity
  }

  const result = /^#?([A-Fa-f\d]{2})([A-Fa-f\d]{2})([A-Fa-f\d]{2})$/.exec(hex)
  if (result) {
    const r = parseInt(result[1], 16)
    const g = parseInt(result[2], 16)
    const b = parseInt(result[3], 16)
    return `${r},${g},${b},${opacity}`
  } else {
    return null // Invalid hex code
  }
}
