import { gql } from 'graphql-tag'
import { fragmentModuleNavigationLink } from '../../../../core/data-layer/modules/fragments'

export default gql`
    ${fragmentModuleNavigationLink}
    fragment BlockRelatedLinksFragment on BlockRelatedLinks {
        sys {
            id
        }
        title
        relatedLinksCollection {
            items {
                ...ModuleNavigationLinkFragment
            }
        }
    }
`
