export const mapperBlockFaqGroup = (data: any) => {
  if (!data) {
    return null
  }
  return {
    title: data.title || '',
    description: data.description || '',
    items: data.itemsCollection?.items || []
  }
}
