import { gql } from 'graphql-tag'
import { fragmentModuleNavigationLink } from '../../../core/data-layer/modules/fragments'
import { fragmentBlockLogoBar } from '../../../core/data-layer/blocks/fragments'
import { fragmentBlockLandingUspList, fragmentBlockCampaignsMedia, fragmentBlockLandscapeMediaThenContent, fragmentBlockContentCenteredTextOnly, fragmentBlockRelatedContent, fragmentBlockSimpleQuote, fragmentBlockLandingMediaAndText, fragmentBlockLandingLogoBar } from '../fragments'
import fragmentBlockCampaignsCards from '../blocks/block-campaigns-cards/fragment-block-campaigns-cards'
import fragmentBlockCampaignsCallOut from '../blocks/block-campaigns-call-out/fragment-block-campaigns-call-out'
import { fragmentBlockCampaignsThankYouContent } from '../blocks/fragments'
import fragmentBlockCampaignsMetrics from '../blocks/block-campaigns-metrics/fragment-block-campaigns-metrics'
import { fragmentGlobalDataTheme } from '../../../core/data-layer/global-data/fragments'

export default gql`
    ${fragmentModuleNavigationLink}
    ${fragmentGlobalDataTheme}
    ${fragmentBlockLandingUspList}
    ${fragmentBlockLogoBar}
    ${fragmentBlockCampaignsMedia}
    ${fragmentBlockLandscapeMediaThenContent}
    ${fragmentBlockContentCenteredTextOnly}
    ${fragmentBlockRelatedContent}
    ${fragmentBlockSimpleQuote}
    ${fragmentBlockLandingMediaAndText}
    ${fragmentBlockLandingLogoBar}
    ${fragmentBlockCampaignsCards}
    ${fragmentBlockCampaignsCallOut}
    ${fragmentBlockCampaignsThankYouContent}
    ${fragmentBlockCampaignsMetrics}
    fragment ContentKnowledgeHubCampaignsGuideFragment on ContentKnowledgeHubCampaignsGuide {
        headerLink {
            ...ModuleNavigationLinkFragment
        }
        secondaryHeaderLink {
            ...ModuleNavigationLinkFragment
        }
        headerEyebrow
        headerSubtitle {
            json
        }
        formId
        showFormInHeader
        formAnchorLabel
        formTitle
        formDescription {
            json
        }
        contentBlocksCollection {
            items {
                contentType: __typename
                ...on BlockLandingMediaAndText {
                    ...BlockLandingMediaAndTextFragment
                }
                ...on BlockContentCenteredTextOnly {
                    ...BlockContentCenteredTextOnlyFragment
                }
                ...on BlockLandscapeMediaThenContent {
                    ...BlockLandscapeMediaThenContentFragment
                }
                ...on BlockCampaignsMetrics {
                    ...BlockCampaignsMetricsFragment
                }
                ...on BlockCampaignsMedia {
                    ...BlockCampaignsMediaFragment
                }
                ...on BlockLandingUspList {
                    ...BlockLandingUspListFragment
                }
                ...on BlockSimpleQuote {
                    ...BlockSimpleQuoteFragment
                }
                ...on BlockCampaignsCards {
                    ...BlockCampaignsCardsFragment
                }
                ...on BlockCampaignsCallOut {
                    ...BlockCampaignsCallOutFragment
                }
                ...on BlockLogoBar {
                    ...BlockLogoBarFragment
                }
                ...on BlockLandingLogoBar {
                    ...BlockLandingLogoBarFragment
                }
            }
        }
        relatedContent {
            ...BlockRelatedContentFragment
        }
        thankYouContent {
            ...BlockCampaignsThankYouContentFragment
        }
        heroTheme {
            ...GlobalDataThemeFragment
        }
    }
`
