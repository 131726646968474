import imgModule from '../../constants/imageSizes'
// when the import happens in the server it is processed as a module, and we need to retrieve the .default
const imageSizes = imgModule?.default || imgModule

export default {
  contentType: 'PageHome',
  fields: [
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns5_34) }
    },
    {
      id: 'logoBar1',
      name: 'Logo bar 1',
      type: 'BlockLogoBar'
    },
    {
      id: 'benefits',
      name: 'Benefits',
      type: 'BlockGroup',
      subType: 'ModuleUsp'
    },
    {
      id: 'contentBlock1',
      name: 'Content block 1',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_34) }
    },
    {
      id: 'contentBlock1Links',
      name: 'Content block 1 links',
      type: 'Array',
      subType: 'ModuleNavigationLink'
    },
    {
      id: 'contentBlock2',
      name: 'Content block 2',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns12_169) }
    },
    {
      id: 'contentBlock3',
      name: 'Content block 3',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_11) }
    },
    {
      id: 'contentBlock3Links',
      name: 'Content block 3 links',
      type: 'Array',
      subType: 'ModuleNavigationLink'
    },
    {
      id: 'contentBlock4Title',
      name: 'Content block 4 title',
      type: 'Text'
    },
    {
      id: 'contentBlock4Description',
      name: 'Content block 4 description',
      type: 'Text'
    },
    // {
    //   id: 'contentBlock4Tab1Title',
    //   name: 'Content block Tab1 Title',
    //   type: 'Text'
    // },
    // {
    //   id: 'contentBlock4Tab1ContentTitle',
    //   name: 'Content block Tab1 Content Title',
    //   type: 'Text'
    // },
    // {
    //   id: 'contentBlock4Tab1ContentDescription',
    //   name: 'Content block Tab1 Content Description',
    //   type: 'Text'
    // },
    // {
    //   id: 'contentBlock4Tab1ContentLink',
    //   name: 'Content block Tab1 Content Link',
    //   type: 'ModuleNavigationLink'
    // },
    // {
    //   id: 'contentBlock4Tab1ContentImage',
    //   name: 'Content block Tab1 Content Image',
    //   type: 'ModuleMedia'
    // },
    // {
    //   id: 'contentBlock4Tab1LogoBar',
    //   name: 'Content block Tab1 LogoBar',
    //   type: 'BlockLogoBar'
    // },
    // {
    //   id: 'contentBlock4Tab2Title',
    //   name: 'Content block Tab2 Title',
    //   type: 'Text'
    // },
    // {
    //   id: 'contentBlock4Tab2ContentTitle',
    //   name: 'Content block Tab2 Content Title',
    //   type: 'Text'
    // },
    // {
    //   id: 'contentBlock4Tab2ContentDescription',
    //   name: 'Content block Tab2 Content Description',
    //   type: 'Text'
    // },
    // {
    //   id: 'contentBlock4Tab2ContentLink',
    //   name: 'Content block Tab2 Content Link',
    //   type: 'ModuleNavigationLink'
    // },
    // {
    //   id: 'contentBlock4Tab2ContentImage',
    //   name: 'Content block Tab2 Content Image',
    //   type: 'ModuleMedia'
    // },
    // {
    //   id: 'contentBlock4Tab2LogoBar',
    //   name: 'Content block Tab2 LogoBar',
    //   type: 'BlockLogoBar'
    // },
    // {
    //   id: 'contentBlock4Tab3Title',
    //   name: 'Content block Tab3 Title',
    //   type: 'Text'
    // },
    // {
    //   id: 'contentBlock4Tab3ContentTitle',
    //   name: 'Content block Tab3 Content Title',
    //   type: 'Text'
    // },
    // {
    //   id: 'contentBlock4Tab3ContentDescription',
    //   name: 'Content block Tab3 Content Description',
    //   type: 'Text'
    // },
    // {
    //   id: 'contentBlock4Tab3ContentLink',
    //   name: 'Content block Tab3 Content Link',
    //   type: 'ModuleNavigationLink'
    // },
    // {
    //   id: 'contentBlock4Tab3ContentImage',
    //   name: 'Content block Tab3 Content Image',
    //   type: 'ModuleMedia'
    // },
    // {
    //   id: 'contentBlock4Tab3LogoBar',
    //   name: 'Content block Tab3 LogoBar',
    //   type: 'BlockLogoBar'
    // },
    {
      id: 'bottomPageCta',
      name: 'Bottom page CTA',
      type: 'BlockCallToAction'
    }
  ]
}
