import { gql } from 'graphql-tag'

export default gql`
  fragment BlockEventFormFragment on BlockEventForm {
    sys {
      id
    }
    formId
    description {
      json
    }
    endDate
  }
`
