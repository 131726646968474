import { gql } from 'graphql-tag'
import { fragmentModuleMetric } from '../../modules/fragments'

export default gql`
    ${fragmentModuleMetric}
    fragment BlockMetricsFragment on BlockMetrics {
        sys {
            id
        }
        contentType: __typename
        title
        twoColsOnDesktop
        graphStyle
        itemsCollection {
            items {
                ...ModuleMetricFragment
            }
        }
    }
`
