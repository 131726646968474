import { gql } from 'graphql-tag'
import { fragmentModuleMedia, fragmentModuleNavigationLink } from '../../../../core/data-layer/modules/fragments'
import { fragmentGlobalDataTheme } from '../../../../core/data-layer/global-data/fragments'

export default gql`
    ${fragmentGlobalDataTheme}
    ${fragmentModuleMedia}
    ${fragmentModuleNavigationLink}
    fragment BlockLandingMediaAndTextFragment on BlockLandingMediaAndText {
        sys {
            id
        }
        title
        blockLandingMediaAndTextDescription: description {
            json
        }
        mediaPosition: orientation
        mediaAspectRatio: mediaAspectRatio
        media {
            ... on ModuleMedia {
                ...ModuleMediaFragment
            }
        }
        link {
            ...ModuleNavigationLinkFragment
        }
        sectionTheme {
            ...GlobalDataThemeFragment
        }
    }
`
