import { gql } from 'graphql-tag'

export default gql`
  fragment GlobalDataArticleTypeFragment on GlobalDataArticleType {
    contentType: __typename
    sys {
      id
    }
    internalName
    name
    icon
  }
`
