import { mapperModuleMedia } from '../../../core/data-layer/modules/mappers'
import imageSizes from '../../../core/constants/imageSizes.js'

/**
 * Page careers locations mapper
 * normalizes data to be used in pages
 * @param {Object} data - cms data
 * @return {Object} - normalized page data
 */
export default (data) => {
  if (!data) {
    return null
  }

  return {
    description: data.description || '',
    subtitle: data.subtitle || '',
    locationsTitle: data.locationsTitle || '',
    headingMedia: mapperModuleMedia(data.headingMedia, { resize: true, sizes: imageSizes.columns12_5269 })
  }
}
