import { gql } from 'graphql-tag'

export default gql`
  fragment BlockTabContentLazyFragment on BlockTabContent {
    title
    description {
      json
    }
    tabContentCollection {
      items {
        sys {
          id
        }
        contentType: __typename
      }
    }
  }
`
