import { gql } from 'graphql-tag'
import { fragmentModuleNavigationLink, fragmentModuleUsp } from '../../modules/fragments'
import fragmentBlockCallToAction from '../block-call-to-action/fragment-block-call-to-action'
import fragmentBlockContent from '../block-content/fragment-block-content'
import fragmentBlockTabContentLazy from '../block-tab-content/fragment-block-tab-content-lazy'
import fragmentBlockUspList from '../block-usp-list/fragment-block-usp-list'

export default gql`
  ${fragmentModuleUsp}
  ${fragmentBlockContent}
  ${fragmentBlockTabContentLazy}
  ${fragmentBlockCallToAction}
  ${fragmentModuleNavigationLink}
  ${fragmentBlockUspList}
  fragment BlockGroupFragment on BlockGroup {
    sys {
      id
    }
    title
    description
    eyebrow
    link {
      ...ModuleNavigationLinkFragment
    }
    itemsCollection {
      items {
        ... on BlockContent {
          ...BlockContentFragment
        }
        ... on ModuleUsp {
          ...ModuleUspFragment
        }
        ... on BlockTabContent {
          ...BlockTabContentLazyFragment
        }
        ... on BlockCallToAction {
          ...BlockCallToActionFragment
        }
        ... on BlockUspList {
          ...BlockUspListFragment
        }
        contentType: __typename
      }
    }
  }
`
