import imgModule from '../../constants/imageSizes.js'
// when the import happens in the server it is processed as a module, and we need to retrieve the .default
const imageSizes = imgModule?.default || imgModule

const heroImageSizes = Object.values(imageSizes.columns5_34)
const heroVerticalImageSizes = Object.values(imageSizes.columns12_5269)

const PageIndustryMobilityPayments = {
  contentType: 'PageIndustryMobilityPayments',
  fields: [
    {
      id: 'notificationBar',
      name: 'Notification Bar',
      type: 'BlockNotificationBar'
    },
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: heroImageSizes }
    },
    {
      id: 'benefits',
      name: 'Benefits',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    {
      id: 'quote',
      name: 'Quote',
      type: 'BlockQuote'
    },
    {
      id: 'quoteVideo',
      name: 'quoteMedia',
      type: ['ModuleVideo', 'ModuleInternalVideo'],
      imageOptions: { resize: true, sizes: imageSizes.columns12_5269 }
    },
    {
      id: 'contentBlock1',
      name: 'Content block 1',
      type: 'BlockGroup',
      subType: 'BlockContent',
      imageOptions: { resize: true, sizes: imageSizes.columns6_43, lazy: true }
    },
    {
      id: 'contentBlock1Extra',
      name: 'Content block 1 extra',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: imageSizes.columns6_43, lazy: true }
    },
    {
      id: 'callToAction1',
      name: 'Call to action 1',
      type: 'BlockCallToAction'
    },
    {
      id: 'contentBlock2',
      name: 'Content block 2',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: imageSizes.columns6_43, lazy: true }
    },
    {
      id: 'experiencesUsps',
      name: 'Experiences usps',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    {
      id: 'image',
      name: 'image',
      type: 'ModuleMedia',
      imageOptions: { resize: true, sizes: imageSizes.columns12_5269, lazy: true }
    },
    {
      id: 'contentBlock3',
      name: 'Content block 3',
      type: 'BlockGroup'
    },
    {
      id: 'contentBlock4',
      name: 'Content block 4',
      type: 'BlockContent'
    },
    {
      id: 'callToAction2',
      name: 'Call to action 2',
      type: 'BlockCallToAction'
    }
  ]
}

const PageIndustryRetailPayments = {
  contentType: 'PageIndustryRetailPayments',
  fields: [
    {
      id: 'notificationBar',
      name: 'Notification Bar',
      type: 'BlockNotificationBar'
    },
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: heroImageSizes }
    },
    {
      id: 'benefits',
      name: 'Benefits',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    {
      id: 'introVideo',
      name: 'Intro video',
      type: ['ModuleVideo', 'ModuleInternalVideo'],
      imageOptions: { resize: true, sizes: imageSizes.columns12_5269 }
    },
    {
      id: 'logoBar1',
      name: 'Logo bar 1',
      type: 'BlockLogoBar'
    },
    {
      id: 'quote',
      name: 'quote',
      type: 'BlockQuote'
    },
    {
      id: 'contentBlock1',
      name: 'Content block 1',
      type: 'BlockGroup'
    },
    {
      id: 'contentBlock2',
      name: 'Content block 2',
      type: 'BlockGroup',
      imageOptions: { resize: true, sizes: imageSizes.columns6_43, lazy: true }
    },
    {
      id: 'contentBlock3',
      name: 'Content block 3',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: imageSizes.columns12_5269, lazy: true }
    },
    {
      id: 'contentBlock4',
      name: 'Content block 4',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: imageSizes.columns6_11, lazy: true }
    },
    {
      id: 'usps',
      name: 'Usps',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    {
      id: 'image',
      name: 'Image',
      type: 'ModuleMedia',
      imageOptions: { resize: true, sizes: imageSizes.columns12_5269, lazy: true }
    },
    {
      id: 'contentBlock5',
      name: 'Content block 5',
      type: 'BlockGroup',
      subType: 'ModuleUsp'
    },
    {
      id: 'logoBar2',
      name: 'Logo bar 2',
      type: 'BlockLogoBar'
    },
    {
      id: 'contentBlock6',
      name: 'Content block 6',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: imageSizes.columns6_43, lazy: true }
    },
    {
      id: 'callToAction',
      name: 'Call to action',
      type: 'BlockCallToAction'
    },
    {
      id: 'relatedContent',
      name: 'Related content',
      type: 'BlockRelatedContent',
      localized: true
    }
  ]
}

const PageIndustrySubscriptionPayments = {
  contentType: 'PageIndustrySubscriptionPayments',
  fields: [
    {
      id: 'notificationBar',
      name: 'Notification Bar',
      type: 'BlockNotificationBar'
    },
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: heroVerticalImageSizes }
    },
    {
      id: 'benefits',
      name: 'Benefits',
      type: 'BlockGroup'
    },
    {
      id: 'contentBlock1',
      name: 'Content block 1',
      type: 'BlockGroup',
      imageOptions: { resize: true, sizes: imageSizes.columns6_11, injectContentType: true, lazy: true }
    },
    {
      id: 'logoBar1',
      name: 'Logo bar 1',
      type: 'BlockLogoBar'
    },
    {
      id: 'contentBlock2',
      name: 'Content block 2',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: imageSizes.columns6_11, lazy: true }
    },
    {
      id: 'recoverUsps',
      name: 'Recover usps',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    {
      id: 'logoBar2',
      name: 'Logo bar 2',
      type: 'BlockLogoBar'
    },
    {
      id: 'contentBlock3',
      name: 'Content block 3',
      type: 'BlockContent'
    },
    {
      id: 'cta',
      name: 'Call to action',
      type: 'BlockCallToAction'
    },
    {
      id: 'relatedContent',
      name: 'Related content',
      type: 'BlockRelatedContent',
      localized: true
    }
  ]
}

const PageIndustryHotelPayments = {
  contentType: 'PageIndustryHotelPayments',
  fields: [
    {
      id: 'notificationBar',
      name: 'Notification Bar',
      type: 'BlockNotificationBar'
    },
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: heroImageSizes }
    },
    {
      id: 'benefits',
      name: 'Benefits',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    {
      id: 'introVideo',
      name: 'Intro video',
      type: ['ModuleVideo', 'ModuleInternalVideo'],
      imageOptions: { resize: true, sizes: imageSizes.columns12_5269 }
    },
    {
      id: 'logoBar1',
      name: 'Logo bar 1',
      type: 'BlockLogoBar'
    },
    {
      id: 'quote',
      name: 'quote',
      type: 'BlockQuote'
    },
    {
      id: 'contentBlock1',
      name: 'Content block 1',
      type: 'BlockGroup',
      imageOptions: { resize: true, sizes: imageSizes.columns6_43, lazy: true, injectContentType: true }
    },
    {
      id: 'logoBar2',
      name: 'Logo bar 2',
      type: 'BlockLogoBar'
    },
    {
      id: 'image',
      name: 'Image',
      type: 'ModuleMedia',
      imageOptions: { resize: true, sizes: imageSizes.columns12_5269, lazy: true }
    },
    {
      id: 'contentBlock2',
      name: 'Content block 2',
      type: 'BlockGroup',
      subType: 'ModuleUsp'
    },
    {
      id: 'contentBlock3',
      name: 'Content block 3',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: imageSizes.columns6_43, lazy: true }
    },
    {
      id: 'callToAction',
      name: 'Call to action',
      type: 'BlockCallToAction'
    },
    {
      id: 'relatedContent',
      name: 'Related content',
      type: 'BlockRelatedContent',
      localized: true
    }
  ]
}

const PageIndustryDigitalPayments = {
  contentType: 'PageIndustryDigitalPayments',
  fields: [
    {
      id: 'notificationBar',
      name: 'Notification Bar',
      type: 'BlockNotificationBar'
    },
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: heroImageSizes }
    },
    {
      id: 'logoBar1',
      name: 'Logo bar 1',
      type: 'BlockLogoBar'
    },
    {
      id: 'contentBlock1',
      name: 'Content block 1',
      type: 'BlockGroup',
      subType: 'ModuleUsp'
    },
    {
      id: 'introVideo',
      name: 'Intro video',
      type: ['ModuleVideo', 'ModuleInternalVideo'],
      imageOptions: { resize: true, sizes: imageSizes.columns12_5269 }
    },
    {
      id: 'contentBlock2',
      name: 'Content block 2',
      type: 'BlockGroup',
      subType: 'ModuleUsp'
    },
    {
      id: 'contentBlock3',
      name: 'Content block 3',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: imageSizes.columns6_43, lazy: true }
    },
    {
      id: 'contentBlock4',
      name: 'Content block 4',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: imageSizes.columns12_5269, lazy: true }
    },
    {
      id: 'logoBar2',
      name: 'Logo bar 2',
      type: 'BlockLogoBar'
    },
    {
      id: 'contentBlock5',
      name: 'Content block 5',
      type: 'BlockGroup',
      subType: 'BlockContent'
    },
    {
      id: 'cta',
      name: 'Call to action',
      type: 'BlockCallToAction'
    }
  ]
}

const PageIndustryFoodBeveragePayments = {
  contentType: 'PageIndustryFoodBeveragePayments',
  fields: [
    {
      id: 'notificationBar',
      name: 'Notification Bar',
      type: 'BlockNotificationBar'
    },
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: heroImageSizes }
    },
    {
      id: 'benefits',
      name: 'Benefits',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    {
      id: 'introVideo',
      name: 'Intro video',
      type: ['ModuleVideo', 'ModuleInternalVideo'],
      imageOptions: { resize: true, sizes: imageSizes.columns12_5269 }
    },
    {
      id: 'logoBar1',
      name: 'Logo bar 1',
      type: 'BlockLogoBar'
    },
    {
      id: 'quote',
      name: 'quote',
      type: 'BlockQuote'
    },
    {
      id: 'contentBlock1',
      name: 'Content block 1',
      type: 'BlockGroup',
      imageOptions: { resize: true, sizes: imageSizes.columns6_43, lazy: true }
    },
    {
      id: 'contentBlockBanner',
      name: 'Content block banner',
      type: 'BlockContent'
    },
    {
      id: 'contentBlock2',
      name: 'Content block 2',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: imageSizes.columns6_43, lazy: true }
    },
    {
      id: 'benefits2',
      name: 'Benefits 2',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    {
      id: 'image',
      name: 'Image',
      type: 'ModuleMedia'
    },
    {
      id: 'contentBlock3',
      name: 'Content block 3',
      type: 'BlockGroup',
      subType: 'ModuleUsp'
    },
    {
      id: 'logoBar2',
      name: 'Logo bar 2',
      type: 'BlockLogoBar'
    },
    {
      id: 'contentBlock4',
      name: 'Content block 4',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: imageSizes.columns6_43, lazy: true }
    },
    {
      id: 'cta',
      name: 'Call to action',
      type: 'BlockCallToAction'
    }
  ]
}

const PageIndustryOverview = {
  contentType: 'PageIndustryOverview',
  fields: [
    {
      id: 'notificationBar',
      name: 'Notification Bar',
      type: 'BlockNotificationBar'
    },
    {
      id: 'eyebrow',
      name: 'Eyebrow',
      type: 'Text',
      localized: true
    },
    {
      id: 'digitalTitle',
      name: 'Digital title',
      type: 'Text',
      localized: true
    },
    {
      id: 'digitalOverviewLink',
      name: 'Digital overview link',
      type: 'ModuleNavigationLink'
    },
    {
      id: 'digitalPages',
      name: 'Digital pages',
      type: 'Array',
      subType: ['PageIndustrySubscriptionPayments', 'PageIndustryDigitalPayments', 'PageIndustryMobilityPayments'], // more be added...
      localized: true
    },
    {
      id: 'omnichannelTitle',
      name: 'Omnichannel title',
      type: 'Text',
      localized: true
    },
    {
      id: 'omnichannelOverviewLink',
      name: 'Omnichannel overview link',
      type: 'ModuleNavigationLink'
    },
    {
      id: 'omnichannelPages',
      name: 'Omnichannel pages',
      type: 'Array',
      subType: ['PageIndustryRetailPayments', 'PageIndustryFoodBeveragePayments', 'PageIndustryHotelPayments'],
      localized: true
    },
    {
      id: 'platformsTitle',
      name: 'Platforms title',
      type: 'Text',
      localized: true
    },
    {
      id: 'platformsOverviewLink',
      name: 'Platforms overview link',
      type: 'ModuleNavigationLink'
    },
    {
      id: 'platformsPages',
      name: 'Platforms pages',
      type: 'Array',
      subType: ['PageIndustrySaasPlatforms'],
      localized: true
    },
    {
      id: 'cta',
      name: 'Call to action',
      type: 'BlockCallToAction'
    }
  ]
}

// Common page fields definitions to be used in the 3 industries overviews: Digital, Omnichannel and Platform
const commonPageFields = [
  {
    id: 'eyebrow',
    name: 'Eyebrow',
    type: 'Text',
    localized: true
  },
  {
    id: 'shortDescription',
    name: 'Short description',
    type: 'Text',
    localized: true
  },
  {
    id: 'logobar',
    name: 'Logo bar',
    type: 'BlockLogoBar'
  },
  {
    id: 'contentBlock1',
    name: 'Content block 1',
    type: 'BlockContent'
  },
  {
    id: 'media',
    name: 'Media image or video',
    type: ['ModuleMedia', 'ModuleVideo', 'ModuleInternalVideo'],
    imageOptions: { resize: true, sizes: imageSizes.columns12_5269 }
  },
  {
    id: 'simpleQuote',
    name: 'Simple Quote',
    type: 'BlockSimpleQuote'
  },
  {
    id: 'cardsBlock',
    name: 'Cards block',
    type: 'BlockMediaCardGroup',
    imageOptions: { resize: true, sizes: imageSizes.columns6_11 }
  },
  {
    id: 'contentBlock2',
    name: 'Content block 2',
    type: 'BlockContent',
    imageOptions: { resize: true, sizes: imageSizes.columns6_11 }
  },
  {
    id: 'contentBlock3',
    name: 'Content block 3',
    type: ['BlockLogoBar', 'BlockGroup']
  },
  {
    id: 'relatedContent',
    name: 'Related content',
    type: 'BlockRelatedContent',
    imageOptions: { resize: true, sizes: imageSizes.related_articles, lazy: true }
  },
  {
    id: 'cta',
    name: 'Call to action',
    type: 'BlockCallToAction'
  }
]

const PageIndustryDigital = {
  contentType: 'PageIndustryDigital',
  fields: [
    ...commonPageFields,
    {
      id: 'digitalPages',
      name: 'Digital pages',
      type: 'Array',
      subType: ['PageIndustrySubscriptionPayments', 'PageIndustryDigitalPayments', 'PageIndustryMobilityPayments'], // more be added...
      localized: true
    },
    {
      id: 'notificationBar',
      name: 'Notification Bar',
      type: 'BlockNotificationBar'
    }
  ]
}

const PageIndustryOmnichannel = {
  contentType: 'PageIndustryOmnichannel',
  fields: [
    ...commonPageFields,
    {
      id: 'omnichannelPages',
      name: 'Omnichannel pages',
      type: 'Array',
      subType: ['PageIndustryRetailPayments', 'PageIndustryFoodBeveragePayments', 'PageIndustryHotelPayments'],
      localized: true
    },
    {
      id: 'notificationBar',
      name: 'Notification Bar',
      type: 'BlockNotificationBar'
    }
  ]
}

const PageIndustryPlatforms = {
  contentType: 'PageIndustryPlatforms',
  fields: [
    ...commonPageFields,
    {
      id: 'notificationBar',
      name: 'Notification Bar',
      type: 'BlockNotificationBar'
    },
    {
      id: 'platformsPages',
      name: 'Platforms pages',
      type: 'Array',
      subType: ['PageIndustrySaasPlatforms'],
      localized: true
    }
  ]
}

const PageIndustrySaasPlatforms = {
  contentType: 'PageIndustrySaasPlatforms',
  fields: [
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: heroImageSizes }
    },
    {
      id: 'benefits',
      name: 'Benefits',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    {
      id: 'contentBlock1',
      name: 'Content block 1',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: imageSizes.columns6_43, lazy: true }
    },
    {
      id: 'logoBar',
      name: 'Logo bar',
      type: 'BlockLogoBar'
    },
    {
      id: 'contentBlock2',
      name: 'Content block 2',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: imageSizes.columns6_43, lazy: true }
    },
    {
      id: 'quote',
      name: 'Quote',
      type: 'BlockSimpleQuote'
    },
    {
      id: 'contentBlock3',
      name: 'Content block 3',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: imageSizes.columns6_43, lazy: true }
    },
    {
      id: 'benefits2',
      name: 'Benefits 2',
      type: 'BlockUspList'
    },
    {
      id: 'cta',
      name: 'Call to action',
      type: 'BlockCallToAction'
    },
    {
      id: 'notificationBar',
      name: 'Notification Bar',
      type: 'BlockNotificationBar'
    }
  ]
}

export default {
  PageIndustryMobilityPayments,
  PageIndustryRetailPayments,
  PageIndustrySubscriptionPayments,
  PageIndustryFoodBeveragePayments,
  PageIndustryHotelPayments,
  PageIndustryDigitalPayments,
  PageIndustryOverview,
  PageIndustryDigital,
  PageIndustryOmnichannel,
  PageIndustryPlatforms,
  PageIndustrySaasPlatforms
}
