import { gql } from 'graphql-tag'
import fragmentBlockCampaignsCardsItem from '../block-campaigns-cards-item/fragment-block-campaigns-cards-item'
import { fragmentGlobalDataTheme } from '../../../../core/data-layer/global-data/fragments'

export default gql`
    ${fragmentBlockCampaignsCardsItem}
    ${fragmentGlobalDataTheme}
    fragment BlockCampaignsCardsFragment on BlockCampaignsCards {
        title
        itemsCollection {
            items {
                ...BlockCampaignsCardsItemFragment
            }
        }
        theme {
            ...GlobalDataThemeFragment
        }
    }
`
