import { mapperPageDynamic } from '../../dynamic/mappers'
import type { MapperOptions } from '../../../types'
import { mapperPageBreadcrumbs, mapperPageSeo } from './mapper-page-seo-breadcrumbs'

/**
 * Mapper page
 * normalizes data to be used in pages
 */

export default (data: any, options: MapperOptions, layerMappers: any) => {
  if (!data) {
    return {}
  }

  const mappedContent = mapperPageDynamic(data, options, layerMappers)
  return {
    sysId: data.sys?.id,
    publishedAt: data.sys?.publishedAt,
    slug: data.slug,
    locale: options.locale,
    category: data.category || '',
    subcategory: data.subcategory || '',
    seo: mapperPageSeo(data.seo),
    contentType: data.contentType,
    content: {
      title: data.title || '',
      ...mappedContent
    },
    breadcrumb: mapperPageBreadcrumbs(data.parent, options)
  }
}
