import { gql } from 'graphql-tag'
import { fragmentModuleNavigationLink } from '../../../../core/data-layer/modules/fragments'
import { fragmentGlobalDataTheme } from '../../../../core/data-layer/global-data/fragments'

export default gql`
    ${fragmentGlobalDataTheme}
    ${fragmentModuleNavigationLink}
    fragment BlockContentCenteredTextOnlyFragment on BlockContentCenteredTextOnly {
        sys {
            id
        }
        title
        blockContentCenteredTextOnlyDescription: description {
            json
        }
        link {
            ...ModuleNavigationLinkFragment
        }
        sectionTheme {
            ...GlobalDataThemeFragment
        }
    }
`
