import { gql } from 'graphql-tag'
import {fragmentModuleNavigationLink, fragmentModuleMedia} from '../../modules/fragments'

export default gql`
  ${fragmentModuleNavigationLink}
  ${fragmentModuleMedia}
  fragment BlockCallToActionWithMediaFragment on BlockCallToActionWithMedia {
    sys {
      id
    }
    title
    text
    link {
      ...ModuleNavigationLinkFragment
    }
    image {
      ...ModuleMediaFragment
    }
  }
`
