import { gql } from 'graphql-tag'
import fragmentModuleNavigationLink from '../../modules/module-navigation-link/fragment-module-navigation-link'

export default gql`
    ${fragmentModuleNavigationLink}
    fragment BlockNotificationBarFragment on BlockNotificationBar {
        sys {
            id
        }
        icon
        link {
            ...ModuleNavigationLinkFragment
        }
    }
`
