import { gql } from 'graphql-tag'
import { fragmentBlockSocialShare } from '../../../../core/data-layer/blocks/fragments'
import { fragmentModuleMedia, fragmentModuleVideo, fragmentModuleMediaCard, fragmentModuleInternalVideo } from '../../../../core/data-layer/modules/fragments'
import { fragmentBlockRelatedLinks } from '../../fragments'
import fragmentPageKnowledgeHubDetail from '../../pages/page-knowledge-hub-detail/fragment-page-knowledge-hub-detail'
import fragmentBlockSimpleRelatedArticles from '../block-related-articles/fragment-block-simple-related-article'

export default gql`
    ${fragmentModuleMedia}
    ${fragmentModuleVideo}
    ${fragmentModuleInternalVideo}
    ${fragmentBlockSocialShare}
    ${fragmentBlockSimpleRelatedArticles}
    ${fragmentBlockRelatedLinks}
    ${fragmentModuleMediaCard}
    ${fragmentPageKnowledgeHubDetail}
    fragment BlockKnowledgeHubChapterFragment on BlockKnowledgeHubChapter {
        sys {
            id
        }
        media {
            contentType: __typename
            ... on ModuleMedia {
                ...ModuleMediaFragment
            }
            ... on ModuleVideo {
                ...ModuleVideoFragment
            }
            ...on ModuleInternalVideo {
                ...ModuleInternalVideoFragment
            }
        }
        title
        navigationTitle
        text {
            json
        }
        sidebarItem {
            contentType: __typename
            ... on BlockSocialShare {
                ...BlockSocialShareFragment
            }
            ... on BlockSimpleRelatedArticles {
                ...BlockSimpleRelatedArticlesFragment
            }
            ... on BlockRelatedLinks {
                ...BlockRelatedLinksFragment
            }
            ... on ModuleMediaCard {
                ...ModuleMediaCardFragment
            }
            ... on PageKnowledgeHubDetail {
                ...PageKnowledgeHubDetailFragment
            }
        }
    }
`
