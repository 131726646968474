import { gql } from 'graphql-tag'
import { fragmentModuleUsp } from '../../../../core/data-layer/modules/fragments'
import { fragmentGlobalDataTheme } from '../../../../core/data-layer/global-data/fragments'

export default gql`
    ${fragmentGlobalDataTheme}
    ${fragmentModuleUsp}
    fragment BlockLandingUspListFragment on BlockLandingUspList {
        contentType: __typename
        title
        blockLandingDescription: description
        itemsCollection {
            items {
                ... on ModuleUsp {
                    ...ModuleUspFragment
                }
                contentType: __typename
            }
        }
        sectionTheme {
            ...GlobalDataThemeFragment
        }
    }
`
