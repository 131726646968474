import { gql } from 'graphql-tag'
import { fragmentModuleMedia } from '../../../../core/data-layer/modules/fragments'
import { fragmentBlockLogoBar } from '../../../../core/data-layer/blocks/fragments'
import fragmentBlockCampaignsMedia from '../../blocks/block-campaigns-media/fragment-block-campaigns-media'
import fragmentBlockLandingLogoBar from '../../blocks/block-landing-logo-bar/fragment-landing-block-logo-bar'
import fragmentBlockRelatedContent from '../../blocks/block-related-content/fragment-block-related-content'

export default gql`
    ${fragmentModuleMedia}
    ${fragmentBlockCampaignsMedia}
    ${fragmentBlockLogoBar}
    ${fragmentBlockLandingLogoBar}
    ${fragmentBlockRelatedContent}
    fragment BlockCampaignsThankYouContentFragment on BlockCampaignsThankYouContent {
        title
        description
        directDownloadMedia {
            ...ModuleMediaFragment
        }
        contentBlocksCollection {
            items {
                contentType: __typename
                ...on BlockLogoBar {
                    ...BlockLogoBarFragment
                }
                ...on BlockLandingLogoBar {
                    ...BlockLandingLogoBarFragment
                }
                ...on BlockCampaignsMedia {
                    ...BlockCampaignsMediaFragment
                }
            }
        }
        relatedContent {
            ...BlockRelatedContentFragment
        }
    }
`
