import { gql } from 'graphql-tag'

export default gql`
  fragment ModuleCodeSnippetFragment on ModuleCodeSnippet {
    sys {
      id
    } 
    language
    sourceCode
  }
`
