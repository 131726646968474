import { mapperModuleUsp } from '../../modules/mappers'

/**
 * Block module list mapper
 * @param {Object} data - cms response data
 * @return {Object} the normalized data
 */
export const mapperBlockUspList = (data: any) => {
  if (!data) {
    return null
  }

  return {
    title: data.title || '',
    subtitle: data.subtitle || '',
    items: data.itemsCollection?.items?.map((item) => {
      const contentType = item.contentType

      if (contentType === 'ModuleUsp') {
        return mapperModuleUsp(item)
      }
      return item
    }) || []
  }
}
