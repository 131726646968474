import { gql } from 'graphql-tag'
import { fragmentModuleMedia } from '../../../core/data-layer/modules/fragments'
import fragmentBlockCampaignsMetrics from '../blocks/block-campaigns-metrics/fragment-block-campaigns-metrics'
import fragmentBlockContent from '../../../core/data-layer/blocks/block-content/fragment-block-content'
export default gql`
    ${fragmentModuleMedia}
    ${fragmentBlockCampaignsMetrics}
    ${fragmentBlockContent}
    fragment ContentKnowledgeHubRr23Fragment on ContentKnowledgeHubRr23 {
        hero {
            ...BlockContentFragment
        }
        statsBlock {
            ...BlockCampaignsMetricsFragment
        }
        image {
            ...ModuleMediaFragment
        }
        formId
        formTitle
        formDescription {
            json
        }
        thankYouTitle
        thankYouDescription {
            json
        }
    }
`
