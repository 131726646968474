import { gql } from 'graphql-tag'

export default gql`
  fragment ModuleSocialItemFragment on ModuleSocialItem {
    sys {
      id
    } 
    iconName
    url
  }
`
