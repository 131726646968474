import { gql } from 'graphql-tag'
import { fragmentModuleVideo, fragmentModuleNavigationLink, fragmentModuleMedia, fragmentModuleInternalVideo } from '../../modules/fragments'

export default gql`
  ${fragmentModuleMedia}
  ${fragmentModuleVideo}
  ${fragmentModuleInternalVideo}
  ${fragmentModuleNavigationLink}
  fragment BlockContentFragment on BlockContent {
    sys {
      id
    }
    title
    contentDescription: description {
      json
    }
    icon
    eyebrow
    linksCollection {
      items {
        ...ModuleNavigationLinkFragment
      }
    }
    image {
      ...ModuleMediaFragment
    }
    video {
      ...on ModuleVideo {
        ...ModuleVideoFragment
      }
      ...on ModuleInternalVideo {
        ...ModuleInternalVideoFragment
      }
    }
  }
`
