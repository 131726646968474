import imageSizes from '../../constants/imageSizes'

const heroImageSizes = Object.values(imageSizes.columns5_34)
// const heroVerticalImageSizes = Object.values(imageSizes.columns12_169)

const PageProductOnlinePayments = {
  contentType: 'PageProductOnlinePayments',
  fields: [
    {
      id: 'notificationBar',
      name: 'Notification Bar',
      type: 'BlockNotificationBar'
    },
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: heroImageSizes }
    },
    {
      id: 'logoBar',
      name: 'Logo bar',
      type: 'BlockLogoBar'
    },
    {
      id: 'benefits',
      name: 'Benefits',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    {
      id: 'image',
      name: 'Image',
      type: 'ModuleMedia',
      imageOptions: { resize: true, sizes: imageSizes.columns12_52 }
    },
    {
      id: 'contentBlock1',
      name: 'Content block 1',
      type: 'BlockGroup',
      subType: 'BlockContent'
    },
    {
      id: 'contentBlock2',
      name: 'Content block 2',
      type: 'BlockContent',
      imageOptions: {
        resize: true,
        sizes: {
          small: { width: 327, height: 70 },
          medium: { width: 672, height: 150 },
          large: { width: 1200, height: 158 }
        }
      }
    },
    {
      id: 'contentBlock3',
      name: 'Content block 3',
      type: 'BlockGroup',
      subType: 'BlockContent'
    },
    // NEW: Image
    {
      id: 'image2',
      name: 'Image',
      type: 'ModuleMedia',
      imageOptions: { resize: true, sizes: imageSizes.columns12_52 }
    },
    // NEW: Content Block 4 - Block - USP
    {
      id: 'contentBlockUsp',
      name: 'Content block USP',
      type: 'BlockUspList'
    },
    // RENAME: Content Block 5 -> rename block 4 to 5 (check if needed)
    {
      id: 'contentBlock4',
      name: 'Content block 4',
      type: 'BlockGroup',
      subType: 'BlockTabContentLazy',
      lazy: true,
      imageOptions: {
        resize: true,
        sizes: {
          small: { width: 336, height: 'auto', aspectRatio: '16/9' },
          medium: { width: 320, height: 'auto', aspectRatio: '16/9' },
          large: { width: 368, height: 'auto', aspectRatio: '16/9' }
        }
      }
    },
    // RENAME: Content Block 6 -> rename block 7 to 6 (check if needed)
    {
      id: 'contentBlock7',
      name: 'Content block 7',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: imageSizes.columns6_11, lazy: true }
    },
    // RENAME to CTA block ?
    {
      id: 'contentBlock8',
      name: 'Content block 8',
      type: 'BlockCallToAction'
    },
    // NEW: FAQ Block
    {
      id: 'faq',
      name: 'FAQ',
      type: 'BlockFaqGroup'
    }
  ]
}

export default {
  PageProductOnlinePayments
}
