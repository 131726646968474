import { gql } from 'graphql-tag'
import { fragmentModuleMedia } from '../../../../core/data-layer/modules/fragments'
import { fragmentGlobalDataTheme } from '../../../../core/data-layer/global-data/fragments'

export default gql`
    ${fragmentModuleMedia}
    ${fragmentGlobalDataTheme}
    fragment BlockSimpleQuoteFragment on BlockSimpleQuote {
        sys {
            id
        }
        logo {
            ...ModuleMediaFragment
        }
        quote
        author
        authorDetails
        theme {
            ...GlobalDataThemeFragment
        }
    }

`
