import { gql } from 'graphql-tag'
import { fragmentGlobalDataPersona } from '../../global-data/fragments'
import { fragmentModuleTable, fragmentModuleMedia, fragmentModuleMediaCard } from '../../modules/fragments'
import fragmentBlockLogoBar from '../block-logo-bar/fragment-block-logo-bar'
import fragmentBlockContent from '../block-content/fragment-block-content'

export default gql`
  ${fragmentBlockLogoBar}
  ${fragmentBlockContent}
  ${fragmentGlobalDataPersona}
  ${fragmentModuleTable}
  ${fragmentModuleMedia}
  ${fragmentModuleMediaCard}
  fragment BlockTabContentFragment on BlockTabContent {
    title
    description {
      json
    }
    tabContentCollection(limit: 12) {
      items {
        ...on BlockContent {
          ...BlockContentFragment
        }
        ...on BlockLogoBar {
          ...BlockLogoBarFragment
        }
        ...on GlobalDataPersona {
          ...GlobalDataPersonaFragment
        }
        ...on ModuleTable {
          ...ModuleTableFragment
        }
        ...on ModuleMedia {
          ...ModuleMediaFragment
        }
        ...on ModuleMediaCard {
          ...ModuleMediaCardFragment
        }
        contentType: __typename
      }
    }
  }
`
