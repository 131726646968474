import imgModule from '../../constants/imageSizes'
// when the import happens in the server it is processed as a module, and we need to retrieve the .default
const imageSizes = imgModule?.default || imgModule

const PageAbout = {
  contentType: 'PageAbout',
  fields: [
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent'
    },
    {
      id: 'benefits',
      name: 'Benefits',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    {
      id: 'introMedia',
      name: 'Intro media',
      type: ['ModuleVideo', 'ModuleInternalVideo'],
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns12_5269), lazy: true }
    },
    {
      id: 'logoBar',
      name: 'Logo bar',
      type: 'BlockLogoBar'
    },
    {
      id: 'contentBlock1',
      name: 'Content block 1',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns12_52), lazy: true }
    },
    {
      id: 'contentBlock2',
      name: 'Content block 2',
      type: 'BlockGroup',
      subType: 'ModuleUsp'
    },
    {
      id: 'adyenFormula',
      name: 'Adyen formula',
      type: 'BlockCompanyFormula',
      options: { transformShortDescription: false }
    },
    {
      id: 'contentBlock3',
      name: 'Content block 3',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_11), lazy: true }
    },
    {
      id: 'locationsTitle',
      name: 'Locations title',
      type: 'Symbol'
    },
    {
      id: 'locationsLink',
      name: 'Locations link',
      type: 'ModuleNavigationLink'
    },
    {
      id: 'cta',
      name: 'Call to action',
      type: 'BlockCallToAction'
    }
  ]
}

const PageAboutTeam = {
  contentType: 'PageAboutTeam',
  fields: [
    {
      id: 'contentBlock',
      name: 'Content block',
      type: 'BlockGroup',
      subType: 'BlockTabContentLazy',
      lazy: true,
      imageOptions: {
        resize: true,
        sizes: [
          { width: 272, height: 272, aspectRatio: '1/1' },
          { width: 368, height: 368, aspectRatio: '1/1' },
          { width: 368, height: 368, aspectRatio: '1/1' }

        ],
        lazy: true
      }
    },
    {
      id: 'backLink',
      name: 'Back link',
      type: 'ModuleNavigationLink'
    }
  ]
}

export default {
  PageAbout,
  PageAboutTeam
}
