import { gql } from 'graphql-tag'

export default gql`
  fragment BlockLiveStreamVideoFragment on BlockLiveStreamVideo {
    sys {
      id
    }
    title
    link
    startDate
    endDate
  }
`
