import { mapperModuleMedia, mapperModuleUsp, mapperModuleVideo } from '../../../core/data-layer/modules/mappers'
import { mapperBlockQuoteGroup, mapperBlockContent } from '../../../core/data-layer/blocks/mappers'
import { mapperGlobalDataCity, mapperGlobalDataOffice } from '../../../core/data-layer/global-data/mappers'
import imageSizes from '../../../core/constants/imageSizes.js'
import type { MapperOptions } from '~/types'

/**
 * Page careers locations detail mapper
 * normalizes data to be used in pages
 */
export default (data: any, options: MapperOptions) => {
  if (!data) {
    return null
  }
  const imageOptionsOffice = {
    lazy: true,
    sizes: { small: { width: 327, height: 184, aspectRatio: '16/9' }, medium: { width: 324, height: 432, aspectRatio: '3/4' }, large: { width: 576, height: 432, aspectRatio: '4/3' } },
    resize: true
  }
  const imageOptionsTestimonials = {
    lazy: true,
    sizes: imageSizes.columns3_169,
    resize: true
  }

  return {
    description: data.description || '',
    headingMedia: mapperModuleMedia(data.headingMedia, { resize: true, sizes: imageSizes.columns12_5269 }),
    headingVideo: mapperModuleVideo(data.headingVideo, { resize: true, sizes: imageSizes.columns12_5269 }),
    testimonials: mapperBlockQuoteGroup(data.testimonials, { ...options, imageOptions: imageOptionsTestimonials }),
    city: mapperGlobalDataCity(data.city),
    office: mapperGlobalDataOffice(data.office, imageOptionsOffice),
    locationFacts: data.locationFactsCollection?.items?.map(mapperModuleUsp) || [],
    perksTitle: data.perksTitle || '',
    perks: data.perksCollection?.items?.map(mapperModuleUsp).filter(Boolean) || [],
    disclaimer: data.disclaimer || null,
    techHub: mapperBlockContent(data.techHub, { ...options, imageOptions: imageOptionsOffice })
  }
}
