import { mapperBlockCallToAction } from '../block-call-to-action/mapper-block-call-to-action'
import { mapperBlockContent } from '../block-content/mapper-block-content'
import { mapperBlockTabContentLazy } from '../block-tab-content/mapper-block-tab-content-lazy'
import { mapperBlockUspList } from '../block-usp-list/mapper-block-usp-list'
import { mapperModuleNavigationLink, mapperModuleUsp } from '../../modules/mappers'
import type { MapperOptions } from '../../../types'

export const mapperBlockGroup = (data: any, options: MapperOptions) => {
  if (!data) {
    return null
  }

  return {
    id: data.sys?.id || '',
    title: data.title || '',
    description: data.description || '',
    eyebrow: data.eyebrow || '',
    link: mapperModuleNavigationLink(data.link, options.getUrl),
    items: data.itemsCollection?.items?.map((item) => {
      const mappedItem = mapChildItem(item, options)
      if (options.injectContentType) {
        mappedItem.contentType = item.contentType
      }

      return mappedItem
    }) || []
  }
}

function mapChildItem(item: any, options: MapperOptions) {
  const contentType = item.contentType
  if (contentType === 'BlockTabContent') {
    return mapperBlockTabContentLazy(item)
  }
  if (contentType === 'BlockContent') {
    return mapperBlockContent(item, options)
  }
  if (contentType === 'ModuleUsp') {
    return mapperModuleUsp(item)
  }
  if (contentType === 'BlockUspList') {
    return mapperBlockUspList(item)
  }
  if (contentType === 'BlockCallToAction') {
    return mapperBlockCallToAction(item, options.getUrl)
  }
  return item
}
