import imgModule from '../../constants/imageSizes.js'
// when the import happens in the server it is processed as a module, and we need to retrieve the .default
const imageSizes = imgModule?.default || imgModule
const heroImageSizes = Object.values(imageSizes.columns5_34)

const PageCharity = {
  contentType: 'PageCharities',
  fields: [
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: heroImageSizes }
    },
    {
      id: 'benefits',
      name: 'Benefits',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    {
      id: 'contentBlock1',
      name: 'Content block 1',
      type: 'BlockGroup',
      subType: 'BlockTabContentLazy',
      lazy: true
    },
    {
      id: 'contentBlock2',
      name: 'Content block 2',
      type: 'BlockContent'
    },
    {
      id: 'quote',
      name: 'Quote',
      type: 'Array',
      subType: 'BlockQuote'
    },
    {
      id: 'contentBlock3',
      name: 'Content block 3',
      type: 'BlockContent'
    },
    {
      id: 'ContentBlock3Additional',
      name: 'Content block 3 additional',
      type: 'RichText'
    },
    {
      id: 'cta1',
      name: 'Call to action 1',
      type: 'BlockCallToAction'
    },
    {
      id: 'cta2',
      name: 'Call to action 2',
      type: 'BlockCallToAction'
    }
  ]
}

const PageCharityLibrary = {
  contentType: 'PageCharitiesLibrary',
  fields: [
    {
      id: 'heroDescription',
      name: 'Description',
      type: 'Text'
    },
    {
      id: 'CharityHighlight',
      name: 'Charity Highlight',
      type: 'BlockHighlight'
    },
    {
      id: 'cta',
      name: 'Call to action ',
      type: 'BlockCallToAction'
    }
  ]
}

const PageCharityDetails = {
  contentType: 'PageCharitiesDetail',
  fields: [
    {
      id: 'charityImage',
      name: 'Charity Image',
      type: 'ModuleMedia'
    },
    {
      id: 'charityName',
      name: 'Charity name',
      type: 'Symbol'
    },
    {
      id: 'charityDescription',
      name: 'Charity description',
      type: 'Text'
    },
    {
      id: 'quote',
      name: 'Quote',
      type: 'BlockQuote'
    },
    {
      id: 'image',
      name: 'image',
      type: 'ModuleMedia'
    },
    {
      id: 'charitiesSDG',
      name: 'SDGs',
      type: 'Array',
      subtype: 'ModuleCharitiesSDG'
    },
    {
      id: 'donationDescription',
      name: 'Charity donation description',
      type: 'Text'
    },
    {
      id: 'cta',
      name: 'Call to action ',
      type: 'BlockCallToAction'
    }
  ]
}

const CharityLabels = {
  contentType: 'CharitiesLabels',
  fields: [
    {
      id: 'region',
      name: 'Region',
      type: 'Symbol'
    },
    {
      id: 'country',
      name: 'Country',
      type: 'Symbol'
    },
    {
      id: 'charitiesSDG',
      name: 'SDGs',
      type: 'Symbol'
    },
    {
      id: 'noResult',
      name: 'No Result',
      type: 'Symbol'
    },
    {
      id: 'found',
      name: 'Charities found',
      type: 'Symbol'
    },
    {
      id: 'donationAction',
      name: 'Your donation in action',
      type: 'Symbol'
    },
    {
      id: 'otherCharities',
      name: 'Explore other charities',
      type: 'Symbol'
    },
    {
      id: 'charityHub',
      name: 'Charity hub',
      type: 'Symbol'
    }
  ]
}

const ModuleCharitySDG = {
  contentType: 'ModuleCharitiesSDG',
  fields: [
    {
      id: 'name',
      name: 'SDG name',
      type: 'Symbol'
    },
    {
      id: 'icon',
      name: 'SDG icon name',
      type: 'Symbol'
    }
  ]
}

export default {
  PageCharity,
  PageCharityLibrary,
  PageCharityDetails,
  CharityLabels,
  ModuleCharitySDG
}
