import { gql } from 'graphql-tag'
import { fragmentModuleMedia, fragmentModuleVideo, fragmentModuleInternalVideo, fragmentModuleNavigationLink } from '../../../../core/data-layer/modules/fragments'
import { fragmentGlobalDataTheme } from '../../../../core/data-layer/global-data/fragments'

export default gql`
    ${fragmentGlobalDataTheme}
    ${fragmentModuleMedia}
    ${fragmentModuleVideo}
    ${fragmentModuleInternalVideo}
    ${fragmentModuleNavigationLink}
    fragment BlockLandscapeMediaThenContentFragment on BlockLandscapeMediaThenContent {
        sys {
            id
        }
        title
        blockLandscapeMediaThenContentDescription: description {
            json
        }
        media {
            ... on ModuleMedia {
                contentType: __typename
                ...ModuleMediaFragment
            }
            ... on ModuleVideo {
                contentType: __typename
                ...ModuleVideoFragment
            }
            ... on ModuleInternalVideo {
                contentType: __typename
                ...ModuleInternalVideoFragment
            }
        }
        link {
            ...ModuleNavigationLinkFragment
        }
        sectionTheme {
            ...GlobalDataThemeFragment
        }
    }
`
