import { gql } from 'graphql-tag'
import fragmentModuleMedia from '../../../../core/data-layer/modules/module-media/fragment-module-media'
import { fragmentGlobalDataTheme } from '../../../../core/data-layer/global-data/fragments'
import fragmentModuleNavigationLink from '../../../../core/data-layer/modules/module-navigation-link/fragment-module-navigation-link'

export default gql`
    ${fragmentModuleMedia}
    ${fragmentModuleNavigationLink}
    ${fragmentGlobalDataTheme}
    fragment BlockLandingLogoBarFragment on BlockLandingLogoBar {
        sys {
            id
        }
        logosCollection (limit: 12) {
            items {
                ...ModuleMediaFragment
            }
        }
        title
        logosDescription: description {
            json
        }
        link {
            ...ModuleNavigationLinkFragment
        }
        footnote
        theme {
            ...GlobalDataThemeFragment
        }
    }
`
