import { gql } from 'graphql-tag'

export default gql`
  fragment GlobalDataLanguageFragment on GlobalDataLanguage {
    sys {
      id
    }
    internalName
    name
    code
  }
`
