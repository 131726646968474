import { gql } from 'graphql-tag'
import fragmentModuleMedia from '../module-media/fragment-module-media'
import fragmentModuleVideo from '../module-video/fragment-module-video'
import fragmentModuleInternalVideo from '../module-internal-video/fragment-module-internal-video'
import fragmentModuleNavigationLink from '../module-navigation-link/fragment-module-navigation-link'

export default gql`
  ${fragmentModuleNavigationLink}
  ${fragmentModuleMedia}
  ${fragmentModuleVideo}
  ${fragmentModuleInternalVideo}
  fragment ModuleMediaCardFragment on ModuleMediaCard {
      sys {
        id
      }
      image {
        ...ModuleMediaFragment
      }
      video {
        ...on ModuleVideo {
          ...ModuleVideoFragment
        }
        ... on ModuleInternalVideo {
          ...ModuleInternalVideoFragment
        }
      }
      icon
      title
      cardDescription: description
      link {
        ...ModuleNavigationLinkFragment
      }
  }
`
