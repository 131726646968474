import imgModule from '../../constants/imageSizes.js'

// when the import happens in the server it is processed as a module, and we need to retrieve the .default
const imageSizes = imgModule?.default || imgModule

const ModuleTerminalHardwareSpecs = {
  contentType: 'ModuleTerminalHardwareSpecs',
  fields: [
    {
      id: 'dimensions',
      name: 'Dimensions (size)',
      type: 'Symbol'
    },
    // {
    //   id: 'deviceVariant',
    //   name: 'Device variant',
    //   type: 'Symbol',
    //   hideCompare: true
    // },
    {
      id: 'printer',
      name: 'Printer Availability',
      type: 'Boolean'
    },
    {
      id: 'userInterface',
      name: 'User Interface',
      type: 'Symbol'
    },
    {
      id: 'paperRollSize',
      name: 'Paper roll size',
      type: 'Symbol'
    },
    {
      id: 'speakerAudio',
      name: 'Speaker + audio jack',
      type: 'Boolean',
      hideCompare: true
    },
    {
      id: 'powerSupply',
      name: 'Power Supply (PSU)',
      type: 'Symbol'
    },
    {
      id: 'weight',
      name: 'Weight',
      type: 'Symbol'
    },
    {
      id: 'battery',
      name: 'Battery type',
      type: 'Symbol'
    },
    {
      id: 'processor',
      name: 'Processor',
      type: 'Symbol'
    },
    {
      id: 'memory',
      name: 'Memory',
      type: 'Symbol'
    },
    {
      id: 'operatingSystem',
      name: 'Operating system',
      type: 'Symbol'
    },
    {
      id: 'camerabarcodeScanner',
      name: 'Camera or Barcode Scanner',
      type: 'Symbol'
    },
    {
      id: 'pciValidity',
      name: 'PCI validity',
      type: 'Symbol'
    }
  ]
}
const ModuleTerminalConnectivitySpecs = {
  contentType: 'ModuleTerminalConnectivitySpecs',
  fields: [
    {
      id: 'ethernet',
      name: 'Ethernet',
      type: 'Boolean'
    },
    {
      id: 'connectivity4G',
      name: '4G',
      type: 'Boolean'
    },
    {
      id: 'wifi',
      name: 'Wifi',
      type: 'Boolean'
    },
    {
      id: 'bluetooth',
      name: 'Bluetooth',
      type: 'Symbol'
    }
  ]
}
const ModuleTerminalPaymentExperienceSpecs = {
  contentType: 'ModuleTerminalPaymentExperienceSpecs',
  fields: [
    {
      id: 'giftcards',
      name: 'Accepts giftcards',
      type: 'Boolean'
    },
    {
      id: 'debitCredit',
      name: 'Accepts debit/credit cards',
      type: 'Boolean'
    },
    {
      id: 'localPaymentMehtods',
      name: 'Accepts local payment methods',
      type: 'Boolean'
    },
    {
      id: 'ewallets',
      name: 'Accepts e-wallets',
      type: 'Boolean'
    },
    {
      id: 'recurringPayments',
      name: 'Customer recognition & recurring payments',
      type: 'Boolean'
    },
    {
      id: 'dcc',
      name: 'Dynamic currency conversion',
      type: 'Boolean'
    },
    {
      id: 'tips',
      name: 'Supports tips',
      type: 'Boolean'
    },
    {
      id: 'languages',
      name: 'Supports language customization',
      type: 'Boolean'
    },
    {
      id: 'custom',
      name: 'Supports custom screen & logo',
      type: 'Boolean'
    },
    {
      id: 'taxfree',
      name: 'Tax free shopping',
      type: 'Boolean'
    },
    {
      id: 'contactlessOnly',
      name: 'Contactless only',
      type: 'Boolean'
    },
    {
      id: 'chip',
      name: 'Chip & Swipe card available',
      type: 'Boolean'
    },
    {
      id: 'offline',
      name: 'Offline processing',
      type: 'Boolean'
    },
    {
      id: 'standalone',
      name: 'Standalone',
      type: 'Boolean'
    }
  ]
}
const ModuleTerminalIntegrationSpecs = {
  contentType: 'ModuleTerminalIntegrationSpecs',
  fields: [
    {
      id: 'terminalApi',
      name: 'Terminal API (cloud/local/general)',
      type: 'Boolean'
    },
    {
      id: 'appIntegration',
      name: 'Adyens Payment SDK',
      type: 'Boolean'
    },
    {
      id: 'androidApi',
      name: 'Terminal API - Android payment application',
      type: 'Boolean'
    }
  ]
}
const BlockTerminalSpecs = {
  contentType: 'BlockTerminalSpecs',
  fields: [
    {
      id: 'hardwareTitle',
      name: 'Hardware section title',
      type: 'Symbol'
    },
    {
      id: 'hardware',
      name: 'Hardware specs',
      type: 'Array',
      validations: [{
        size: {
          min: 1,
          max: 3
        }
      }],
      subType: 'ModuleTerminalHardwareSpecs'
    },
    {
      id: 'connectivityTitle',
      name: 'Connectivity section title',
      type: 'Symbol'
    },
    {
      id: 'connectivity',
      name: 'Connectivity specs',
      type: 'Array',
      subType: 'ModuleTerminalConnectivitySpecs',
      validations: [{
        size: {
          min: 1,
          max: 1
        }
      }]
    },
    {
      id: 'paymentExperienceTitle',
      name: 'Payment experience section title',
      type: 'Symbol'
    },
    {
      id: 'paymentExperience',
      name: 'Payment experience specs',
      type: 'Array',
      subType: 'ModuleTerminalPaymentExperienceSpecs',
      validations: [{
        size: {
          min: 1,
          max: 1
        }
      }]
    },
    {
      id: 'integrationTitle',
      name: 'Integration section title',
      type: 'Symbol'
    },
    {
      id: 'integration',
      name: 'Integration specs',
      type: 'Array',
      subType: 'ModuleTerminalIntegrationSpecs',
      validations: [{
        size: {
          min: 1,
          max: 1
        }
      }]
    }
  ]
}
const TerminalsLabels = {
  contentType: 'TerminalsLabels',
  fields: [
    {
      id: 'terminal',
      name: 'Terminal',
      type: 'Symbol'
    },
    {
      id: 'goToDevice',
      name: 'Go to device',
      type: 'Symbol'
    },
    {
      id: 'select',
      name: 'Select',
      type: 'Symbol'
    },
    {
      id: 'remove',
      name: 'Remove',
      type: 'Symbol'
    },
    {
      id: 'all',
      name: 'All',
      type: 'Symbol'
    },
    {
      id: 'MOBILE',
      name: 'Mobile',
      type: 'Symbol'
    },
    {
      id: 'PORTABLE',
      name: 'Portable',
      type: 'Symbol'
    },
    {
      id: 'TAPTOPAY',
      name: 'Tap to Pay',
      type: 'Symbol'
    },
    {
      id: 'COUNTERTOP',
      name: 'Countertop',
      type: 'Symbol'
    },
    {
      id: 'UNATTENDED',
      name: 'Unattended',
      type: 'Symbol'
    },
    {
      id: 'compareTerminals',
      name: 'Compare terminals',
      type: 'Symbol'
    },
    {
      id: 'compare',
      name: 'Compare',
      type: 'Symbol'
    },
    {
      id: 'cancel',
      name: 'Cancel',
      type: 'Symbol'
    },
    {
      id: 'addUpTo',
      name: 'Add up to',
      type: 'Symbol'
    },
    {
      id: 'hardware',
      name: 'Hardware',
      type: 'Symbol'
    },
    {
      id: 'connectivity',
      name: 'Connectivity',
      type: 'Symbol'
    },
    {
      id: 'paymentExperience',
      name: 'Payment experience',
      type: 'Symbol'
    },
    {
      id: 'Integration',
      name: 'Integration',
      type: 'Symbol'
    }
  ]
}
const TerminalsLabelsHardwareSpecs = {
  contentType: 'TerminalsLabelsHardwareSpecs',
  fields: [
    {
      id: 'dimensions',
      name: 'Dimensions (size)',
      type: 'Boolean',
      defaultValue: {
        en: 'Dimensions (size)'
      }
    },
    {
      id: 'printer',
      name: 'Printer Availability',
      type: 'Symbol',
      defaultValue: {
        en: 'Printer Availability'
      }
    },
    {
      id: 'userInterface',
      name: 'User Interface',
      type: 'Symbol',
      defaultValue: {
        en: 'User interface'
      }
    },
    {
      id: 'paperRollSize',
      name: 'Paper roll size',
      type: 'Symbol',
      defaultValue: {
        en: 'Paper roll size'
      }
    },
    {
      id: 'speakerAudio',
      name: 'Speaker + audio jack',
      type: 'Symbol',
      defaultValue: {
        en: 'Speaker + audio jack'
      }
    },
    {
      id: 'weight',
      name: 'Weight',
      type: 'Symbol',
      defaultValue: {
        en: 'Weight'
      }
    },
    {
      id: 'battery',
      name: 'Battery',
      type: 'Symbol',
      defaultValue: {
        en: 'Battery'
      }
    },
    {
      id: 'processor',
      name: 'Processor',
      type: 'Symbol',
      defaultValue: {
        en: 'Processor'
      }
    },
    {
      id: 'memory',
      name: 'Memory',
      type: 'Symbol',
      defaultValue: {
        en: 'Memory'
      }
    },
    {
      id: 'powerSupply',
      name: 'Power Supply (PSU)',
      type: 'Symbol',
      defaultValue: {
        en: 'Power supply'
      }
    },
    {
      id: 'operatingSystem',
      name: 'Operating system',
      type: 'Symbol',
      defaultValue: {
        en: 'Operating System'
      }
    },
    {
      id: 'camerabarcodeScanner',
      name: 'Camera or Barcode Scanner',
      type: 'Symbol',
      defaultValue: {
        en: 'Camera Scanner'
      }
    },
    {
      id: 'pciValidity',
      name: 'PCI validity',
      type: 'Symbol',
      defaultValue: {
        en: 'PCI Validity'
      }
    }
  ]
}
const TerminalsLabelsConnectivitySpecs = {
  contentType: 'TerminalsLabelsConnectivitySpecs',
  fields: [
    {
      id: 'ethernet',
      name: 'Ethernet',
      type: 'Symbol',
      defaultValue: {
        en: 'Ethernet'
      }
    },
    {
      id: 'connectivity4G',
      name: '4G',
      type: 'Symbol',
      defaultValue: {
        en: '4G'
      }
    },
    {
      id: 'ethernet',
      name: 'Ethernet',
      type: 'Symbol',
      defaultValue: {
        en: 'Ethernet'
      }
    },
    {
      id: 'bluetooth',
      name: 'Bluetooth',
      type: 'Symbol',
      defaultValue: {
        en: 'Bluetooth'
      }
    }
  ]
}
const TerminalsLabelsPaymentExperienceSpecs = {
  contentType: 'TerminalsLabelsPaymentExperienceSpecs',
  fields: [
    {
      id: 'giftcards',
      name: 'Accepts giftcards',
      type: 'Symbol',
      defaultValue: {
        en: 'Accepts giftcards'
      }
    },
    {
      id: 'debitCredit',
      name: 'Accepts debit/credit cards',
      type: 'Symbol',
      defaultValue: {
        en: 'Accepts debit/credit cards'
      }
    },
    {
      id: 'localPaymentMehtods',
      name: 'Accepts local payment methods',
      type: 'Symbol',
      defaultValue: {
        en: 'Accepts local payment methods'
      }
    },
    {
      id: 'ewallets',
      name: 'Accepts e-wallets',
      type: 'Symbol',
      defaultValue: {
        en: 'Accepts e-wallets'
      }
    },
    {
      id: 'recurringPayments',
      name: 'Customer recognition & recurring payments',
      type: 'Symbol',
      defaultValue: {
        en: 'Customer recognition & recurring payments'
      }
    },
    {
      id: 'dcc',
      name: 'Dynamic currency conversion',
      type: 'Symbol',
      defaultValue: {
        en: 'Dynamic currency conversion'
      }
    },
    {
      id: 'tips',
      name: 'Supports tips',
      type: 'Symbol',
      defaultValue: {
        en: 'Supports tips'
      }
    },
    {
      id: 'languages',
      name: 'Supports language customization',
      type: 'Symbol',
      defaultValue: {
        en: 'Supports language customization'
      }
    },
    {
      id: 'custom',
      name: 'Supports custom screen & logo',
      type: 'Symbol',
      defaultValue: {
        en: 'Supports custom screen & logo'
      }
    },
    {
      id: 'taxfree',
      name: 'Tax free shopping',
      type: 'Symbol',
      defaultValue: {
        en: 'Tax free shopping'
      }
    },
    {
      id: 'contactless',
      name: 'Contactless only',
      type: 'Symbol',
      defaultValue: {
        en: 'Contactless'
      }
    },
    {
      id: 'chip',
      name: 'Chip & Swipe card available',
      type: 'Symbol',
      defaultValue: {
        en: 'Chip & Swipe card availability'
      }
    },
    {
      id: 'offline',
      name: 'Offline Processing',
      type: 'Symbol',
      defaultValue: {
        en: 'Offline Processing'
      }
    },
    {
      id: 'standalone',
      name: 'Standalone',
      type: 'Symbol',
      defaultValue: {
        en: 'Standalone'
      }
    }
  ]
}
const TerminalsLabelsIntegrationSpecs = {
  contentType: 'TerminalsLabelsIntegrationSpecs',
  fields: [
    {
      id: 'terminalAPI',
      name: 'Terminal API (cloud/local/general)',
      type: 'Symbol',
      defaultValue: {
        en: 'Terminal API (cloud/local/general)'
      }
    },
    {
      id: 'appIntegration',
      name: 'Adyens Payment SDK',
      type: 'Symbol',
      defaultValue: {
        en: 'Adyens Payment SDK'
      }
    },
    {
      id: 'androidApi',
      name: 'Terminal API - Android payment application',
      type: 'Symbol',
      defaultValue: {
        en: 'Terminal API - Android payment application'
      }
    }
  ]
}

const TerminalsUseCases = {
  contentType: 'TerminalsUseCases',
  fields: [
    {
      id: 'title',
      name: 'Title',
      type: 'Symbol',
      localized: true
    },
    {
      id: 'image',
      name: 'Image',
      type: 'ModuleMedia',
      imageOptions: { resize: true, sizes: imageSizes.columns12_52, lazy: true }
    },
    {
      id: 'items',
      name: 'Items',
      type: 'Array',
      subType: 'Symbol',
      validations: [{ size: { min: 0, max: 4 } }],
      items: { type: 'Symbol' },
      localized: true
    }
  ]
}

const PageTerminalsDetail = {
  contentType: 'PageTerminalsDetail',
  fields: [
    {
      id: 'terminalName',
      name: 'Terminal name',
      type: 'Symbol',
      includeInParent: true
    },
    {
      id: 'terminalDescription',
      name: 'Terminal description',
      type: 'Text',
      includeInParent: true
    },
    {
      id: 'specialTag',
      name: 'Special tag to display (ex: Only US)',
      type: 'Symbol',
      includeInParent: true
    },
    {
      id: 'terminalCategory',
      name: 'Terminal category',
      type: 'Symbol',
      includeInParent: true,
      localized: false,
      validations: [
        { in: ['MOBILE', 'PORTABLE', 'COUNTERTOP', 'UNATTENDED'] }
      ]
    },
    {
      id: 'salesLink',
      name: 'Sales link',
      type: 'ModuleNavigationLink'
    },
    {
      id: 'benefits',
      name: 'Benefits',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    {
      id: 'primaryImage',
      name: 'Primary image for the device',
      type: 'ModuleMedia',
      includeInParent: true,
      imageOptions: { resize: true, sizes: imageSizes.columns5_34, lazy: true }
    },
    {
      id: 'secondaryImage',
      name: 'Secondary image for the device',
      type: 'ModuleMedia',
      includeInParent: true,
      imageOptions: { resize: true, sizes: imageSizes.columns5_34, lazy: true }
    },
    {
      id: 'thumbnailImage',
      name: 'Image for the comparison grid',
      type: 'ModuleMedia',
      includeInParent: true,
      imageOptions: { resize: true, sizes: imageSizes.terminals_grid, lazy: true }
    },
    {
      id: 'introMedia',
      name: 'Intro media',
      type: ['ModuleVideo', 'ModuleInternalVideo'],
      imageOptions: { resize: true, sizes: imageSizes.columns12_52, lazy: true }
    },
    {
      id: 'logoBar',
      name: 'Logo bar',
      type: 'BlockLogoBar'
    },
    {
      id: 'specsTitle',
      name: 'Specs title',
      type: 'Symbol'
    },
    {
      id: 'specs',
      name: 'Specs',
      type: 'BlockTerminalSpecsLazy',
      lazy: true
    },
    {
      id: 'cta',
      name: 'Call to action',
      type: 'BlockCallToAction'
    },
    {
      id: 'formId',
      name: 'Form ID',
      type: 'Symbol'
    },
    {
      id: 'formTitle',
      name: 'Form Title',
      type: 'Symbol'
    },
    {
      id: 'formDescription',
      name: 'Form Description',
      type: 'Symbol'
    },
    {
      id: 'footnotes',
      name: 'Footnotes',
      type: 'RichText'
    },
    {
      id: 'contentBlock1',
      name: 'Content Block 1',
      type: 'BlockGroup',
      subType: 'BlockContent'
    },
    {
      id: 'highlighted',
      name: 'Highlight',
      type: 'Boolean',
      includeInParent: true
    },
    {
      id: 'awardImage1',
      name: 'Award Image 1',
      type: 'ModuleMedia'
    },
    {
      id: 'awardImage2',
      name: 'Award Image 2',
      type: 'ModuleMedia'
    },
    {
      id: 'industries',
      name: 'Industries',
      type: 'Array',
      subType: 'GlobalDataIndustry',
      validations: [{ size: { min: 0, max: 10 } }]
    },
    {
      id: 'industriesTitle',
      name: 'Industries Title',
      type: 'Symbol',
      localized: true
    },
    {
      id: 'useCases',
      name: 'Use cases',
      type: 'TerminalsUseCases'
    }
  ]
}
const PageTerminalsComparison = {
  contentType: 'PageTerminalsComparison',
  fields: [
    {
      id: 'backLink',
      name: 'Back link',
      type: 'ModuleNavigationLink'
    },
    {
      id: 'titleLabel',
      name: 'Title label',
      type: 'Symbol'
    },
    {
      id: 'cta',
      name: 'Call to action',
      type: 'BlockCallToAction'
    }
  ]
}
const PageTerminals = {
  contentType: 'PageTerminals',
  fields: [
    {
      id: 'backLink',
      name: 'Back link',
      type: 'ModuleNavigationLink'
    },
    {
      id: 'titleLabel',
      name: 'Title label',
      type: 'Symbol'
    },
    {
      id: 'compareLink',
      name: 'Compare link',
      type: 'ModuleNavigationLink'
    }
  ]
}

export default {
  TerminalsLabels,
  TerminalsLabelsHardwareSpecs,
  TerminalsLabelsConnectivitySpecs,
  TerminalsLabelsPaymentExperienceSpecs,
  TerminalsLabelsIntegrationSpecs,

  ModuleTerminalIntegrationSpecs,
  ModuleTerminalPaymentExperienceSpecs,
  ModuleTerminalConnectivitySpecs,
  ModuleTerminalHardwareSpecs,
  BlockTerminalSpecs,
  TerminalsUseCases,

  PageTerminals,
  PageTerminalsComparison,
  PageTerminalsDetail
}
