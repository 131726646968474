// when the import happens in the server it is processed as a module, and we need to retrieve the .default
import imageSizes from '../../constants/imageSizes'

const heroPortraitImageSizes = imageSizes.columns5_34
const heroLandscapeImageSizes = imageSizes.columns12_5269
const defaultHeroImageSizes = imageSizes.columns12_169

export const BlockKnowledgeHubTopic = {
  contentType: 'BlockKnowledgeHubTopic',
  fields: [{
    id: 'image',
    name: 'Image',
    type: 'ModuleMedia'
  },
  {
    id: 'topic',
    name: 'Topic',
    type: ['GlobalDataTopic', 'GlobalDataArticleType', 'GlobalDataProduct', 'GlobalDataIndustry']
  }]
}

export const BlockKnowledgeHubChapter = {
  contentType: 'BlockKnowledgeHubChapter',
  fields: [{
    id: 'media',
    name: 'Media image or video',
    type: ['ModuleMedia', 'ModuleVideo', 'ModuleInternalVideo']
  }, {
    id: 'title',
    name: 'Title',
    type: 'Symbol'
  }, {
    id: 'navigationTitle',
    name: 'Navigation title',
    type: 'Symbol'
  }, {
    name: 'Text',
    type: 'RichText'
  }]
}

export const PageKnowledgeHubDetail = {
  contentType: 'PageKnowledgeHubDetail',
  fields: [
    {
      id: 'topics',
      name: 'Topics',
      type: 'Array',
      subType: 'GlobalDataTopic',
      validations: [{ size: { min: 1, max: 8 } }]
    },
    {
      id: 'articleType',
      name: 'Article Type',
      type: 'GlobalDataArticleType',
      includeInRelated: true
    },
    {
      id: 'products',
      name: 'Products',
      type: 'Array',
      subType: 'GlobalDataProduct',
      validations: [{ size: { min: 0, max: 5 } }]
    },
    {
      id: 'industries',
      name: 'Industries',
      type: 'Array',
      subType: 'GlobalDataIndustry',
      validations: [{ size: { min: 0, max: 5 } }]
    },
    {
      id: 'image',
      name: 'Image',
      type: 'ModuleMedia',
      includeInRelated: true,
      imageOptions: [
        // Vertical
        {
          resize: true,
          sizes: heroPortraitImageSizes
        },
        // Horizontal
        {
          resize: true,
          sizes: heroLandscapeImageSizes
        },
        // return image 16:9 for gated content
        // columns 6, aspect ratio 16/9 on desktop
        // column 12, aspect ratio 16/9 on tablet and mobile
        {
          resize: true,
          sizes: {
            small: { width: 327, height: 184, aspectRatio: '16/9' },
            medium: { width: 672, height: 378, aspectRatio: '16/9' },
            large: { width: 576, height: 324, aspectRatio: '16/9' }
          }
        },
        {
          resize: true,
          sizes: defaultHeroImageSizes
        }
      ]
    },
    {
      id: 'shortDescription',
      name: 'Short Description',
      type: 'Text',
      includeInRelated: true
    },
    {
      id: 'date',
      name: 'Date',
      type: 'Date',
      includeInRelated: true
    },
    {
      id: 'minutesToRead',
      name: 'Minutes to Read',
      type: 'Integer'
    },
    {
      id: 'gatedContentEnabled',
      name: 'Gated Content Enabled',
      type: 'Boolean'
    },
    {
      id: 'gatedContentFormId',
      name: 'Gated Content Form ID',
      type: 'Symbol'
    },
    {
      id: 'gatedContentFormTitle',
      name: 'Gated Content Form Title',
      type: 'Symbol'
    },
    {
      id: 'gatedContentSuccessTitle',
      name: 'Gated Content Success Title',
      type: 'Symbol'
    },
    {
      id: 'gatedContentSuccessText',
      name: 'Gated Content Success Text',
      type: 'Text'
    },
    {
      id: 'gatedContentSuccessDownload',
      name: 'Gated Content Success Download',
      type: ['ModuleNavigationLink', 'ModuleMedia']
    },
    {
      id: 'pageContent',
      name: 'campaign, retail report, case study or an article',
      type: ['ContentKnowledgeHubArticle', 'ContentKnowledgeHubRr23', 'ContentKnowledgeHubCaseStudy', 'ContentKnowledgeHubCampaignsGuide'],
      lazy: true
    }
  ]
}

export const PageKnowledgeHub = {
  contentType: 'PageKnowledgeHub',
  fields: [
    {
      id: 'notificationBar',
      name: 'Notification Bar',
      type: 'BlockNotificationBar'
    },
    {
      id: 'highlightedTopArticles',
      name: 'Highlighted Top Articles',
      type: 'Array',
      subType: 'PageKnowledgeHubDetail',
      validations: [{ size: { min: 5, max: 5 } }]
    },
    {
      id: 'fixedArticlesTitle',
      name: 'Fixed Articles Title',
      type: 'Symbol'
    },
    {
      id: 'fixedArticlesArticleType',
      name: 'Fixed Articles Article Type',
      type: 'GlobalDataArticleType'
    },
    {
      id: 'highlightBlock',
      name: 'Highlight Block',
      type: 'PageKnowledgeHubDetail'
    },
    {
      id: 'latestPostsTitle',
      name: 'Latest Posts Title',
      type: 'Symbol'
    },
    {
      id: 'popularTopicsTitle',
      name: 'Popular Topics Title',
      type: 'Symbol'
    },
    {
      id: 'popularTopics',
      name: 'Popular Topics',
      type: 'Array',
      subType: 'BlockKnowledgeHubTopic',
      validations: [{ size: { min: 1, max: 4 } }]
    },
    {
      id: 'customRelatedArticlesTitle',
      name: 'Custom Related Articles Title',
      type: 'Symbol'
    },
    {
      id: 'customRelatedArticlesTopic',
      name: 'Custom Related Articles Topic',
      type: ['GlobalDataTopic', 'GlobalDataArticleType', 'GlobalDataProduct', 'GlobalDataIndustry']
    }
  ]
}

export const KnowledgeHubLabels = {
  contentType: 'knowledgeHubLabels',
  fields: [
    {
      id: 'backLink',
      name: 'Back Link Label',
      type: 'Symbol'
    },
    {
      id: 'articleTypeFilter',
      name: 'Article Type Filter',
      type: 'Symbol'
    },
    {
      id: 'topicFilter',
      name: 'Topic Filter',
      type: 'Symbol'
    },
    {
      id: 'industryFilter',
      name: 'Industry Filter',
      type: 'Symbol'
    },
    {
      id: 'productFilter',
      name: 'Products Filter',
      type: 'Symbol'
    },
    {
      id: 'sidebarTopics',
      name: 'Topics for Sidebar',
      type: 'Symbol'
    },
    {
      id: 'sidebarShare',
      name: 'Share for Sidebar',
      type: 'Symbol'
    },
    {
      id: 'relatedArticles',
      name: 'Related Articles Title',
      type: 'Symbol'
    },
    {
      id: 'gatedContentDownloadButton',
      name: 'Gated Content Download Button',
      type: 'Symbol'
    },
    {
      id: 'loadMoreArticlesButton',
      name: 'Load More Articles Button',
      type: 'Symbol'
    }
  ]
}

export const PageKnowledgeHubArticles = {
  contentType: 'PageKnowledgeHubArticles',
  fields: [
    {
      id: 'noResultsTitle',
      name: 'No Results Title',
      type: 'Symbol'
    },
    {
      id: 'noResultsSubtitle',
      name: 'No Results Subtitle',
      type: 'Symbol'
    }
  ]
}

export const ContentKnowledgeHubRr23 = {
  contentType: 'ContentKnowledgeHubRr23',
  fields: [
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: imageSizes.columns5_34 },
      localized: true
    },
    {
      id: 'statsBlock',
      name: 'Statistics Overview',
      type: 'BlockCampaignsMetrics',
      localized: true
    },
    {
      id: 'image',
      name: 'Section Image',
      type: 'ModuleMedia',
      imageOptions: { resize: true, sizes: imageSizes.columns12_52 },
      localized: true
    },
    {
      id: 'formId',
      name: 'Form ID',
      type: 'Symbol',
      required: true
    },
    {
      id: 'formTitle',
      name: 'Form Title',
      type: 'Symbol',
      localized: true
    },
    {
      id: 'formDescription',
      name: 'Form Description',
      type: 'RichText',
      localized: true
    },
    {
      id: 'thankYouTitle',
      name: 'Thank You Title',
      type: 'Symbol',
      localized: true
    },
    {
      id: 'thankYouDescription',
      name: 'Thank You Description',
      type: 'RichText',
      localized: true
    }
  ]
}

export const ContentKnowledgeHubArticle = {
  contentType: 'ContentKnowledgeHubArticle',
  fields: [
    {
      id: 'image',
      name: 'Image',
      type: 'ModuleMedia',
      includeInRelated: true,
      imageOptions: [
        // Vertical
        {
          resize: true,
          sizes: heroPortraitImageSizes
        },
        // Horizontal
        {
          resize: true,
          sizes: heroLandscapeImageSizes
        },
        // return image 16:9 for gated content
        // columns 6, aspect ratio 16/9 on desktop
        // column 12, aspect ratio 16/9 on tablet and mobile
        {
          resize: true,
          sizes: {
            small: { width: 327, height: 184, aspectRatio: '16/9' },
            medium: { width: 672, height: 378, aspectRatio: '16/9' },
            large: { width: 576, height: 324, aspectRatio: '16/9' }
          }
        },
        {
          resize: true,
          sizes: defaultHeroImageSizes
        }
      ]
    },
    {
      id: 'displayFullWidthImageHero',
      name: 'Display Full Width Image Hero',
      type: 'Boolean'
    },
    {
      id: 'author',
      name: 'Author',
      type: 'GlobalDataArticleAuthor'
    },
    {
      id: 'legacyContent',
      name: 'Legacy HTML Content',
      type: 'Text',
      localized: true
    },
    {
      id: 'intro',
      name: 'Intro Text',
      type: 'RichText',
      localized: true
    },
    {
      id: 'related',
      name: 'Related Knowledge Hub Articles',
      type: 'Array',
      subType: 'PageKnowledgeHubDetail',
      validations: [{ size: { min: 0, max: 3 } }],
      localized: true
    },
    {
      id: 'chapters',
      name: 'Chapters',
      type: 'Array',
      subType: 'BlockKnowledgeHubChapter',
      validations: [{ size: { min: 1, max: 10 } }],
      required: false // because we can have the "legacyContent" HTML instead for now
    }
  ]
}

export default {
  BlockKnowledgeHubChapter,
  PageKnowledgeHubDetail,
  BlockKnowledgeHubTopic,
  PageKnowledgeHub,
  KnowledgeHubLabels,
  PageKnowledgeHubArticles,
  ContentKnowledgeHubRr23,
  ContentKnowledgeHubArticle
}
