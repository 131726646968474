import { mapperModuleCompanyFormulaItem, mapperModuleVideo } from '../../../core/data-layer/modules/mappers'
import imageSizes from '../../../core/constants/imageSizes.js'

export default (data: any) => {
  if (!data) {
    return null
  }

  return {
    title: data.title || '',
    description: data.description || '',
    formulaItemsTitle: data.formulaItemsTitle || '',
    formulaItems: data.formulaItemsCollection?.items?.map(
      item => mapperModuleCompanyFormulaItem(item)
    ) || []
  }
}
