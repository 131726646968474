import { mapperModuleMedia } from '../../modules/mappers'

/**
 * GlobalDataCity mapper
 * @param {Object} data - cms response data
 * @return {Object} the normalized data
 */

export default (data) => {
  if (!data) {
    return null
  }

  return {
    id: data.sys?.id || '',
    name: data.name || '',
    uniqueId: data.uniqueId || '',
    image: mapperModuleMedia(data.image, '') || null,
    countryId: data?.country?.sys?.id || '',
    countryName: data?.country?.name || ''
  }
}
