import { gql } from 'graphql-tag'
import fragmentBlockQuote from '../block-quote/fragment-block-quote'

export default gql`
  ${fragmentBlockQuote}
  fragment BlockQuoteGroupFragment on BlockQuoteGroup {
    sys {
      id
    }
    title
    description
    quotesCollection {
      items {
        ...BlockQuoteFragment
      }
    }
  }
`
