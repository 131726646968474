import { gql } from 'graphql-tag'
import fragmentModuleNavigationLink from '../../modules/module-navigation-link/fragment-module-navigation-link'

export default gql`
  ${fragmentModuleNavigationLink}
  fragment BlockCallToActionFragment on BlockCallToAction {
    sys {
      id
    }
    icon
    title
    text
    primaryLink {
      ...ModuleNavigationLinkFragment
    }
    secondaryLink {
      ...ModuleNavigationLinkFragment
    }
  }
`
