import { gql } from 'graphql-tag'

export default gql`
  fragment ModuleLocalizableNavigationLinkFragment on ModuleLocalizableNavigationLink {
    sys {
      id
    }
    title
    text
    openInNewWindow
    externalLink
    internalLink {
      ... on Entry {
        sys {
          id
        }
        contentType: __typename
      }
    }
  }
`
