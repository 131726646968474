import { mapperPageBreadcrumbs, mapperPageSeo } from './mapper-page-seo-breadcrumbs'
import type { MapperOptions } from '~/types'

/**
 * Mapper page
 * normalizes data to be used in pages
 *
 * @param {String} locale - locale code
 * @param {Object} data - cms data
 * @param {Object} routesModule - routesModule plugin
 * @param {Function} routesModule.getUrl - returns localized url
 * @param {Function} routesModule.getInternalUrl - returns localized url only for internal pages
 * @param {Object} labels - labels collection
 * @return {Object} - normalized page data
 */

export default (data: any, options: MapperOptions, layerMappers: any) => {
  if (!data) {
    return {}
  }

  return {
    sysId: data.sys?.id,
    locale: options.locale,
    category: data.category || '',
    subcategory: data.subcategory || '',
    seo: mapperPageSeo(data.seo),
    contentType: data.contentType,
    content: {
      title: data.title || '',
      // eslint-disable-next-line import/namespace
      ...(data.contentType && layerMappers[data.contentType](data, options))
    },
    breadcrumb: mapperPageBreadcrumbs(data.parent, options)
  }
}
