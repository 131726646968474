import { gql } from 'graphql-tag'
import { fragmentModuleMedia } from '../../modules/fragments'

export default gql`
  ${fragmentModuleMedia}
  fragment GlobalDataPersonaFragment on GlobalDataPersona {
    sys {
      id
    }
    name
    role
    shortDescription
    longDescription: description
    image {
      ... on ModuleMedia {
        ... ModuleMediaFragment
      }
    }
    memberSince
    currentTerm
  }
`
