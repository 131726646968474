export default `
  sys {
    id
    publishedAt
  }
  slug
  title
  category
  subcategory
  contentType: __typename
  seo {
    title
    description
    keywords
    ogImage {
      src
      frontifyAsset
    }
    noIndex
    noFollow
  }
`
