import { mapperModuleMedia } from '../../modules/mappers'
import mapperMediaBlocks from '../../utils/mappers/mapper-media-blocks'



/**
 * Reports content block
 */
export default (data: any, getUrl: Function) => {
  if (!data) {
    return null
  }

  const imageOptionsFooterMedia = {
    sizes: { small: { width: 327, height: 184, aspectRatio: '16/9' }, medium: { width: 672, height: 365, aspectRatio: '16/9' }, large: { width: 992, height: 558, aspectRatio: '16/9' } },
    resize: true
  }
  return {
    title: data?.title || '',
    subtitle: data?.subtitle || '',
    text: data?.text || null, // rich text
    footnote: data?.footnote || null, // rich text
    headerMedia: mapperModuleMedia(data.headerMedia, {}),
    sideMedia: mapperMediaBlocks(data.sideMedia, { getUrl, imageOptions: {} }),
    footerMediaItems: data.footerMediaCollection?.items?.map(item => mapperMediaBlocks(item, { getUrl, imageOptions: imageOptionsFooterMedia })).filter(Boolean) || []
  }
}
