import imgModule from '../../constants/imageSizes'

// when the import happens in the server it is processed as a module, and we need to retrieve the .default
const imageSizes = imgModule?.default || imgModule
const heroImageSizes = Object.values(imageSizes.columns5_34)

const PageFinancialIssuing = {
  contentType: 'PageFinancialIssuing',
  fields: [
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: heroImageSizes }
    },
    {
      id: 'benefits',
      name: 'Benefits',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    {
      id: 'contentBlock1',
      name: 'Content block 1',
      type: 'BlockContent',
      imageOptions: {
        resize: true,
        sizes: [{
          width: 327,
          height: 184,
          aspectRatio: '16/9'
        },
        {
          width: 312,
          height: 176,
          aspectRatio: '16/9'
        },
        {
          width: 576,
          height: 324,
          aspectRatio: '16/9'
        }],
        lazy: true
      }
    },
    {
      id: 'contentBlock2',
      name: 'Content block 2',
      type: 'BlockGroup',
      subType: 'BlockTabContentLazy',
      lazy: true,
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_11) }
    },
    {
      id: 'contentBlock3',
      name: 'Content block 3',
      type: 'BlockGroup',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_11), lazy: true }
    },
    {
      id: 'contentBlock4',
      name: 'Content block 4',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_11), lazy: true }
    },
    {
      id: 'contentBlock5',
      name: 'Content block 5',
      type: 'BlockGroup',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_11), lazy: true }
    },
    {
      id: 'contentBlock6',
      name: 'Content block 6',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns12_169), lazy: true }
    },
    {
      id: 'contentBlock7',
      name: 'Content block 7',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_11), lazy: true }
    },
    {
      id: 'callToAction',
      name: 'Call to action',
      type: 'BlockCallToAction'
    },
    {
      id: 'contentBlock8',
      name: 'Content block 8',
      type: 'BlockGroup'
    },
    {
      id: 'callToAction2',
      name: 'Call to action 2',
      type: 'BlockCallToAction'
    },
    {
      id: 'faqs',
      name: 'FAQ',
      type: 'BlockFaqGroup'
    }
  ]
}

// TODO  - Uncomment video CT when ready.
const PageFinancialAccounts = {
  contentType: 'PageFinancialAccounts',
  fields: [
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: heroImageSizes }
    },
    {
      id: 'benefits',
      name: 'Benefits',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    {
      id: 'contentBlock1',
      name: 'Content block 1',
      type: 'BlockGroup'
    },
    {
      id: 'introMedia',
      name: 'Intro media',
      type: 'ModuleMedia',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns12_5269), lazy: true }
    },
    {
      id: 'contentBlock2',
      name: 'Content block 2',
      type: 'BlockGroup',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_11), lazy: true }
    },
    // {
    //   id: 'video',
    //   name: 'Video',
    //   type: 'ModuleVideo',
    //   imageOptions: { resize: true, sizes: Object.values(imageSizes.columns12_52), lazy: true }
    // },
    {
      id: 'contentBlock3',
      name: 'Content block 3',
      type: 'BlockGroup'
    },
    {
      id: 'contentBlock4',
      name: 'Content block 4',
      type: 'BlockGroup'
    },
    {
      id: 'contentBlock5',
      name: 'Content block 5 (with tabs)',
      type: 'BlockGroup',
      subType: 'BlockTabContentLazy',
      lazy: true,
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_11), lazy: true }
    },
    {
      id: 'contentBlock6',
      name: 'Content block 6',
      type: 'BlockGroup'
    },
    {
      id: 'formId',
      name: 'Form ID',
      type: 'Symbol'
    },
    {
      id: 'formTitle',
      name: 'Form Title',
      type: 'Symbol'
    },
    {
      id: 'formDescription',
      name: 'Form Description',
      type: 'Symbol'
    },
    {
      id: 'faqs',
      name: 'FAQ',
      type: 'BlockFaqGroup'
    },
    {
      id: 'footnotes',
      name: 'Footnotes',
      type: 'RichText'
    }
  ]
}

const PageFinancialCapital = {
  contentType: 'PageFinancialCapital',
  fields: [
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: heroImageSizes }
    },
    {
      id: 'benefits',
      name: 'Benefits',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    {
      id: 'contentBlock1',
      name: 'Content block 1',
      type: 'BlockGroup'
    },
    {
      id: 'introMedia',
      name: 'Intro media',
      type: 'ModuleMedia',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns12_5269), lazy: true }
    },
    {
      id: 'contentBlock2',
      name: 'Content block 2',
      type: 'BlockGroup'
    },
    {
      id: 'contentBlock3',
      name: 'Content block 3',
      type: 'BlockGroup',
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_43), lazy: true }
    },
    {
      id: 'contentBlock4',
      name: 'Content block 4 (with tabs)',
      type: 'BlockGroup',
      subType: 'BlockTabContentLazy',
      lazy: true,
      imageOptions: { resize: true, sizes: Object.values(imageSizes.columns6_11), lazy: true }
    },
    {
      id: 'contentBlock5',
      name: 'Content block 5',
      type: 'BlockGroup'
    },
    {
      id: 'formId',
      name: 'Form ID',
      type: 'Symbol'
    },
    {
      id: 'formTitle',
      name: 'Form Title',
      type: 'Symbol'
    },
    {
      id: 'formDescription',
      name: 'Form Description',
      type: 'Symbol'
    },
    {
      id: 'faqs',
      name: 'FAQ',
      type: 'BlockFaqGroup'
    },
    {
      id: 'footnotes',
      name: 'Footnotes',
      type: 'RichText'
    }
  ]
}

export default {
  PageFinancialIssuing,
  PageFinancialAccounts,
  PageFinancialCapital
}
