import { gql } from 'graphql-tag'
import { fragmentModuleMedia } from '../../../../core/data-layer/modules/fragments'

export default gql`
    ${fragmentModuleMedia}
    fragment BlockRelatedContentFragment on BlockRelatedContent {
        sys {
            id
        }
        title
        filtersCollection(limit: 4) {
            items {
                ...on Entry {
                    sys {
                        id
                    }
                    contentType: __typename
                }
            }
        }
        relatedPagesCollection(limit: 4) {
            items {
                sys {
                    id
                }
                image {
                    ...ModuleMediaFragment
                }
                title
                shortDescription
                date
                articleType {
                    name
                }
            }
        }
    }
`
