import { gql } from 'graphql-tag'

export default gql`
  fragment GlobalDataThemeFragment on GlobalDataTheme {
    sys {
      id
    }
    internalName
    theme
    contentType: __typename
  }
`
