import { gql } from 'graphql-tag'
import { fragmentModuleMedia } from '../../modules/fragments'
import { fragmentGlobalDataArticleType, fragmentGlobalDataIndustry, fragmentGlobalDataProduct, fragmentGlobalDataTopic } from '../../global-data/fragments'

export default gql`
  ${fragmentModuleMedia}
  ${fragmentGlobalDataTopic}
  ${fragmentGlobalDataArticleType}
  ${fragmentGlobalDataProduct}
  ${fragmentGlobalDataIndustry}
  fragment BlockKnowledgeHubTopicFragment on BlockKnowledgeHubTopic {
    sys {
      id
    }
    image {
      ...ModuleMediaFragment
    }
    topic {
      contentType: __typename
      ...on GlobalDataTopic {
        ...GlobalDataTopicFragment
      }
      ...on GlobalDataArticleType {
        ...GlobalDataArticleTypeFragment
      }
      ...on GlobalDataProduct {
        ...GlobalDataProductFragment
      }
      ...on GlobalDataIndustry {
        ...GlobalDataIndustryFragment
      }
    }
  }
`
