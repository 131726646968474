import { gql } from 'graphql-tag'

export default gql`
  fragment GlobalDataTopicFragment on GlobalDataTopic {
    sys {
      id
    }
    contentType: __typename
    internalName
    name
    icon
  }
`
