/**
 * Icon mapper
 * @param {String} data - icon data
 * @return {Object} - normalized data
 */
export default (data) => {
  if (!data || !data.includes('--')) {
    return null
  }
  const iconNames = data.split('--')
  return {
    category: iconNames[0],
    name: iconNames[1]
  }
}
