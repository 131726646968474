import { mapperBlockFaqGroup } from '../../../core/data-layer/blocks/mappers'
import { mapperModuleUsp } from '../../../core/data-layer/modules/mappers'

/**
 * Page careers faqs mapper
 * normalizes data to be used in pages
 * @param {Object} data - cms data
 * @param {Function} getUrl - returns localized url
 * @return {Object} - normalized page data
 */
export default (data: any) => {
  if (!data) {
    return null
  }
  return {
    description: data.description || '',
    stepsItems: data.stepsCollection?.items?.map(mapperModuleUsp) || [],
    faq: mapperBlockFaqGroup(data.faq)
  }
}
