/**
 * BlockTabContent
 * @param {Object} data, content data
 * @return {Object} normalized data
 */
export const mapperBlockTabContentLazy = (data: any) => {
  if (!data) {
    return null
  }

  return {
    title: data.title || '',
    description: data.description || null,
    items: data.tabContentCollection?.items.map(i => ({ id: i.sys.id, contentType: i.contentType })) || []
  }
}
