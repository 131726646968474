import { gql } from 'graphql-tag'
import { fragmentModuleUsp } from '../../modules/fragments'

export default gql`
${fragmentModuleUsp}
fragment BlockUspListFragment on BlockUspList {
  sys {
    id
  }
  contentType: __typename
  title
  subtitle
  itemsCollection {
    items {
      ... on ModuleUsp {
        ...ModuleUspFragment
      }
      contentType: __typename
    }
  }
}
`
