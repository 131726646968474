import { gql } from 'graphql-tag'
// eslint-disable-next-line import/extensions
import fragmentModuleVideo from '../module-video/fragment-module-video'
import fragmentModuleInternalVideo from '../module-internal-video/fragment-module-internal-video'

export default gql`
  ${fragmentModuleVideo}
  ${fragmentModuleInternalVideo}
  fragment ModuleCompanyFormulaItemFragment on ModuleCompanyFormulaItem {
    sys {
      id
    }
    name
    icon
    shortDescription {
      json
    }
    description {
      json
    }
    video {
      ...on ModuleVideo {
        ...ModuleVideoFragment
      }
      ... on ModuleInternalVideo {
        ...ModuleInternalVideoFragment
      }
    }
  }
`
