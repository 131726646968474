import type { MapperImageSize, MapperImageSizes } from '../../../types'
import { hexToRgba } from './hexToRgba'
import { DEFAULT_SIZES } from './mapper-image'

/**
 * Determine if the image should use an automatic height
 */
const isHeightAuto = (height: number | string): boolean => !height || height === 'auto' || Number.isNaN(height)

/**
 * getTransformedUrl, adds resizing and cropping transformations to the url
 * @see https://developer.frontify.com/d/XFPCrGNrXQQM/asset-processing-api#/operations/resizing
 *
 * @example
 *  getTransformedUrl('url', 100, 200, hasSizes)
 */
const getTransformedUrl = (url: string, { width, height }: MapperImageSize, focalPoint: Array<number>) => {
  if (!url) {
    return ''
  }

  const [focalPointX, focalPointY] = focalPoint || [0.5, 0.5]

  if (isHeightAuto(height)) {
    // resized using only width to let the height be set automatically based on the image
    return `${url}?width=${width}&format=webp`
  }
  // resize the image with width and height
  return `${url}?width=${width}&height=${height}&format=webp&crop=fp&fp=${focalPointX},${focalPointY}`
}

const getRecoloredUrl = (url: string, recolor: string) => {
  if (!url) {
    return ''
  }
  if (!recolor) {
    return url
  }
  const rgbaColor = hexToRgba(recolor)
  return `${url}?color=${rgbaColor}`
}

/**
 * Get the multiplier for large images to support retina screens
 *
 */
const getImageLargeMultiplier = (largeSizeOption: MapperImageSize, width: number, height: number) => {
  const autoHeight = isHeightAuto(largeSizeOption.height)
  const heightAsNumber = autoHeight ? 0 : largeSizeOption.height as number
  switch (true) {
    case (width >= largeSizeOption.width * 2) && (autoHeight || height >= heightAsNumber * 2):
      // check if the image is big enough to support size*2 on large screens
      return 2
    case (width >= largeSizeOption.width * 1.5) && (autoHeight || height >= heightAsNumber * 1.5):
      // check if the image is big enough to support size*1.5 on large screens
      return 1.5
    default:
      return 1
  }
}

/**
 * Mapper for Frontify images
 */
export default (
  image: any,
  sizes: MapperImageSizes,
  recolor?: string
) => {
  const small = sizes?.small || DEFAULT_SIZES.small
  const medium = sizes?.medium || DEFAULT_SIZES.medium
  const large = sizes?.large || DEFAULT_SIZES.large
  const imageName = image?.title || ''
  const imageUrl = image?.src

  const hasSizes = !!(Object.keys(small).length && Object.keys(medium).length && Object.keys(large).length)

  // When image extension is svg or no sizes are set do not apply any transformations
  if (!hasSizes) {
    const url = image?.src
    return {
      small: { src: url, name: imageName, aspectRatio: null },
      medium: { src: url, name: imageName, aspectRatio: null },
      large: { src: url, name: imageName, aspectRatio: null }
    }
  }

  // Multiply support retina screens
  const imageLargeMultiplier = getImageLargeMultiplier(large, image?.size?.width, image?.size?.height)
  const imageSmall = { width: small.width * 2, height: typeof small.height === 'string' ? small.height : small.height * 2, aspectRatio: small.aspectRatio }
  const imageMedium = { width: medium.width * 2, height: typeof medium.height === 'string' ? medium.height : medium.height * 2, aspectRatio: medium.aspectRatio }
  const imageLarge = {
    width: Math.floor(large.width * imageLargeMultiplier),
    height: typeof large.height === 'string' ? large.height : Math.floor(large.height * imageLargeMultiplier),
    aspectRatio: large.aspectRatio
  }

  const shouldResize = (image.frontifyType === 'Image' && image.extension !== 'svg')

  return {
    small: {
      src: shouldResize ? getTransformedUrl(imageUrl, imageSmall, image.focalPoint) : getRecoloredUrl(imageUrl, recolor),
      name: imageName,
      aspectRatio: shouldResize ? imageSmall.aspectRatio : '',
      dimensions: shouldResize ? { width: small.width, height: small.height } : null
    },
    medium: {
      src: shouldResize ? getTransformedUrl(imageUrl, imageMedium, image.focalPoint) : getRecoloredUrl(imageUrl, recolor),
      name: imageName,
      aspectRatio: shouldResize ? imageMedium.aspectRatio : '',
      dimensions: shouldResize ? { width: medium.width, height: medium.height } : null

    },
    large: {
      src: shouldResize ? getTransformedUrl(imageUrl, imageLarge, image.focalPoint) : getRecoloredUrl(imageUrl, recolor),
      name: imageName,
      aspectRatio: shouldResize ? imageLarge.aspectRatio : '',
      dimensions: shouldResize ? { width: large.width, height: large.height } : null
    }
  }
}
