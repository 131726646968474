export const useCareersPageData = () => {
  const dataLayer = useCareersDataLayer()
  const { useAsyncDataWithQuery, useRelatedPagesAsyncDataWithQuery, useStandardAsyncData } = usePageData('careers', {
    layerMappers: dataLayer.mappers,
    layerFragments: {}
  })

  return {
    useAsyncDataWithQuery,
    useRelatedPagesAsyncDataWithQuery,
    useStandardAsyncData
  }
}
