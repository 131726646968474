import { gql } from 'graphql-tag'

export default gql`
  fragment GlobalDataCountryFragment on GlobalDataCountry {
    sys {
      id
    }
    internalName
    name
    icon
    region {
      sys {
        id
      }
    }
    processingFeeAmount
    processingFeeCurrency {
      sys {
        id
      }
    }
  }
`
