import { gql } from 'graphql-tag'

export default gql`
  fragment GlobalDataCurrencyFragment on GlobalDataCurrency {
    sys {
        id
      }
    name
    isoCode
  }
`
