import { gql } from 'graphql-tag'

export default gql`
  fragment ModuleTableFragment on ModuleTable {
    useColumnHeader
    useRowHeader
    fixedLayout
    columns
    rows
    table
  }
`
