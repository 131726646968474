import type { MapperImageSize, MapperImageSizes } from '../../../types'
import mapperImageFrontify from './mapper-image-frontify'

export const DEFAULT_SIZES: Record<string, MapperImageSize> = {
  small: { width: 327, height: 184, aspectRatio: '16/9' },
  medium: { width: 672, height: 378, aspectRatio: '16/9' },
  large: { width: 1200, height: 480, aspectRatio: '5/2' }
}

/**
 * Determine if the image should use an automatic height
 */
const isHeightAuto = (height: number | string): boolean => !height || height === 'auto' || Number.isNaN(height)

/**
 * getTransformedUrl, adds fill and crop transformations to the url
 * @see https://support.bynder.com/hc/en-us/articles/4407181522450-Transform-Transformations
 *
 * @example
 *  getTransformedUrl('url', 100, 200)
 */
const getTransformedUrl = (url: string, { width, height }: MapperImageSize) => {
  if (!url) {
    return ''
  }

  if (isHeightAuto(height)) {
    // resized using only width to let the height be set automatically based on the image
    // crop is not possible when setting only width
    return `${url}?io=transform:fill,width:${width}`
  }
  // first resize the image
  const transformationSize = `io=transform:fill,width:${width},height:${height}`
  // respect dimensions
  const transformationDimension = `io=transform:crop,width:${width},height:${height}`
  return `${url}?${transformationSize}&${transformationDimension}`
}

/**
 * Get the multiplier for large images to support retina screens
 *
 */
const getImageLargeMultiplier = (largeSizeOption: MapperImageSize, width: number, height: number) => {
  const autoHeight = isHeightAuto(largeSizeOption.height)
  const heightAsNumber = autoHeight ? 0 : largeSizeOption.height as number
  switch (true) {
    case (width >= largeSizeOption.width * 2) && (autoHeight || height >= heightAsNumber * 2):
      // check if the image is big enough to support size*2 on large screens
      return 2
    case (width >= largeSizeOption.width * 1.5) && (autoHeight || height >= heightAsNumber * 1.5):
      // check if the image is big enough to support size*1.5 on large screens
      return 1.5
    default:
      return 1
  }
}

/**
 * Mapper image - in progress - start using in a few places
 */
export default (
  image: any,
  sizes: MapperImageSizes,
  recolor?: string
) => {
  if (image.isFrontifyAsset) {
    return mapperImageFrontify(image, sizes, recolor)
  }
  const small = sizes?.small || DEFAULT_SIZES.small
  const medium = sizes?.medium || DEFAULT_SIZES.small
  const large = sizes?.large || DEFAULT_SIZES.small
  const imageName = image?.name || ''
  const imageUrl = image?.transform
  const originalImageUrl = image?.src
  const imageExtension = image?.extension

  const hasSizes = Object.keys(small).length && Object.keys(medium).length && Object.keys(large).length

  // When image extension is svg or no sizes are set do not apply any transformations
  if (imageExtension === 'svg' || !hasSizes) {
    const url = image?.isPublic ? originalImageUrl : imageUrl
    return {
      small: { src: url, name: imageName, aspectRatio: null },
      medium: { src: url, name: imageName, aspectRatio: null },
      large: { src: url, name: imageName, aspectRatio: null }
    }
  }

  // Multiply support retina screens
  const imageLargeMultiplier = getImageLargeMultiplier(large, image.size.width, image.size.height)
  const imageSmall = { width: small.width * 2, height: typeof small.height === 'string' ? small.height : small.height * 2, aspectRatio: small.aspectRatio }
  const imageMedium = { width: medium.width * 2, height: typeof medium.height === 'string' ? medium.height : medium.height * 2, aspectRatio: medium.aspectRatio }
  const imageLarge = {
    width: Math.floor(large.width * imageLargeMultiplier),
    height: typeof large.height === 'string' ? large.height : Math.floor(large.height * imageLargeMultiplier),
    aspectRatio: large.aspectRatio
  }

  return {
    small: {
      src: getTransformedUrl(imageUrl, imageSmall),
      name: imageName,
      aspectRatio: imageSmall.aspectRatio,
      dimensions: { width: small.width, height: small.height }
    },
    medium: {
      src: getTransformedUrl(imageUrl, imageMedium),
      name: imageName,
      aspectRatio: imageMedium.aspectRatio,
      dimensions: { width: medium.width, height: medium.height }

    },
    large: {
      src: getTransformedUrl(imageUrl, imageLarge),
      name: imageName,
      aspectRatio: imageLarge.aspectRatio,
      dimensions: { width: large.width, height: large.height }
    }
  }
}
