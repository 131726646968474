/**
 * Formula item mapper
 * normalizes data to be used in pages
 * @param {Object} data - cms data
 * @return {Object} - normalized page data
 */
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { mapperModuleVideo } from '../module-video/mapper-module-video'
import mapperIcon from '../../utils/mappers/mapper-icon'

// transformShortDescription is used to transform the short description (Rich Text) to a normal text to be used as a title
export const mapperModuleCompanyFormulaItem = (data: any, transformShortDescription: boolean = false) => {
  if (!data) {
    return null
  }

  const videoOptions = {
    lazy: true,
    sizes: {
      small: {
        width: 327,
        height: 184,
        aspectRatio: '16/9'
      },
      medium: {
        width: 312,
        height: 176,
        aspectRatio: '16/9'
      },
      large: {
        width: 576,
        height: 324,
        aspectRatio: '16/9'
      }
    },
    resize: true
  }

  return {
    name: data.name || '',
    icon: mapperIcon(data.icon) || '',
    shortDescription: transformShortDescription ? documentToPlainTextString(data.shortDescription.json) : data.shortDescription || '',
    description: data.description || '',
    video: mapperModuleVideo(data.video, videoOptions)
  }
}
