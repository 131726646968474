import { mapperModuleMedia } from '../module-media/mapper-module-media'
import type { MapperImageOptions, ModuleMedia } from '../../../types'

/**
 * Gets video source
 * prioritizing internal sources
 */
const getVideoSourceData = (frontifyAsset: any) => {
  if (!frontifyAsset) {
    return null
  }
  const extension = frontifyAsset.ext
  return {
    selfHosted: true,
    extension,
    src: `${frontifyAsset.preview_url}?format=mp4`
  }
}

export const mapperModuleInternalVideo = (data: any, imageOptions: MapperImageOptions) => {
  if (!data) {
    return null
  }

  const frontifyAsset = data.frontifyAsset?.[0]

  if (!frontifyAsset) {
    return {
      id: '',
      title: '',
      thumbnail: null,
      source: '',
      caption: '',
      sizes: {},
      selfHosted: true,
      extension: null,
      src: undefined
    }
  }

  const simulatedModuleMediaForThumbnail = {
    name: data.title,
    frontifyAsset: [
      {
        ext: 'jpg',
        preview_url: data.thumbnailUrl,
        src: data.thumbnailUrl,
        type: 'Image',
        height: frontifyAsset?.height,
        width: frontifyAsset?.width
      }
    ]
  }

  const thumbnailModuleMedia = mapperModuleMedia(simulatedModuleMediaForThumbnail, imageOptions) as ModuleMedia

  return {
    id: data.sys?.id || '',
    title: data?.title || '',
    thumbnail: thumbnailModuleMedia,
    source: data?.source || '',
    caption: data?.caption || '',
    sizes: imageOptions?.sizes || {},
    ...getVideoSourceData(frontifyAsset)
  }
}
