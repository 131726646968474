import { gql } from 'graphql-tag'

export default gql`
  fragment ModuleMediaFragment on ModuleMedia {
    contentType: __typename
    name
    src
    color
    source
    frontifyAsset
  }
`
