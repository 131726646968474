import { gql } from 'graphql-tag'
import fragmentModuleNavigationLink from '../../modules/module-navigation-link/fragment-module-navigation-link'

export default gql`
  ${fragmentModuleNavigationLink}
  fragment BlockFormFragment on BlockForm {
    internalName
    title
    text {
      json
    }
    formTitle
    formId
    link {
      ...ModuleNavigationLinkFragment
    }
    submitLabel
    advancedRedirectConfig
  }
`
