import imgModule from '../../constants/imageSizes'
const imageSizes = imgModule || imgModule
const heroImageSizes = imageSizes.columns12_5269
const galleryItemsSizes = {
  small: { width: 327, height: 327, aspectRatio: '1/1' },
  medium: { width: 252, height: 252, aspectRatio: '1/1' },
  large: { width: 472, height: 472, aspectRatio: '1/1' }
}

export const PageCareersDei = {
  contentType: 'PageCareersDei',
  fields: [
    {
      id: 'image',
      name: 'Hero Image',
      type: 'ModuleMedia',
      imageOptions: { resize: true, sizes: heroImageSizes }
    },
    {
      id: 'description',
      name: 'Hero description',
      type: 'Text',
      localized: true
    },
    {
      id: 'usps',
      name: 'USPs',
      type: 'Array',
      subType: 'ModuleUsp',
      validations: [{ size: { min: 1, max: 6 } }],
      items: { type: 'ModuleUsp' },
      localized: true
    },
    {
      id: 'beYourselfTitle',
      name: 'Be Yourself title',
      type: 'Symbol',
      localized: true
    },
    {
      id: 'beYourselfDescription',
      name: 'Be Yourself description',
      type: 'Text',
      localized: true
    },
    {
      id: 'ergTitle',
      name: 'ERG title',
      type: 'Symbol',
      localized: true
    },
    {
      id: 'ergList',
      name: 'ERG list',
      type: 'Array',
      subType: 'Symbol',
      validations: [{ size: { min: 1, max: 12 } }],
      items: { type: 'Symbol' },
      localized: true
    },
    {
      id: 'imageGallery',
      name: 'Images Gallery',
      type: 'BlockMediaCardGroup',
      imageOptions: { resize: true, sizes: galleryItemsSizes }
    },
    {
      id: 'logos',
      name: 'Logos',
      type: 'BlockLogoBar',
      localized: true
    }
  ]
}

export default {
  PageCareersDei
}
