import { gql } from 'graphql-tag'
import { fragmentModuleMedia } from '../../modules/fragments'

export default gql`
  ${fragmentModuleMedia}
  fragment GlobalDataCityFragment on GlobalDataCity {
    sys {
      id
    }
    name
    uniqueId
    country {
      sys {
        id
      }
      name
    }
    image {
      ...ModuleMediaFragment
    }
  }
`
