import { gql } from 'graphql-tag'

export default gql`
  fragment BlockFaqGroupFragment on BlockFaqGroup {
    sys {
      id
    }
    title
    description
    itemsCollection(limit: 10) {
      items {
        sys {
          id
        }
        title
        text {
          json
        }
      }
    }
  }
`
