import { gql } from 'graphql-tag'
import { fragmentModuleMediaCard, fragmentModuleNavigationLink } from '../../modules/fragments'

export default gql`
  ${fragmentModuleMediaCard}
  ${fragmentModuleNavigationLink}
  fragment BlockMediaCardGroupFragment on BlockMediaCardGroup {
    sys {
      id
    }
    title
    mediaCardDescription: description
    itemsCollection(limit: 10) {
      items {
      ... on ModuleMediaCard {
        ... ModuleMediaCardFragment
        }
      }
    }
    ctaLink {
      ...ModuleNavigationLinkFragment
    }
  }
`
