import { gql } from 'graphql-tag'
import { fragmentGlobalDataTheme } from '../../../../core/data-layer/global-data/fragments'
import fragmentModuleCampaignsMetricsItem from '../../modules/module-campaigns-metrics-item/fragment-module-campaigns-metrics-item';

export default gql`
    ${fragmentModuleCampaignsMetricsItem}
    ${fragmentGlobalDataTheme}
    fragment BlockCampaignsMetricsFragment on BlockCampaignsMetrics {
        title
        landscapeOrientation
        description {
            json
        }
        itemsCollection {
            items {
                ...ModuleCampaignsMetricsItemFragment
            }
        }
        theme {
            ...GlobalDataThemeFragment
        }
    }
`
