import { CareerOffice, CareerTeam, CareerType } from './enums-greenhouse'

const CareerTypeMap = new Map([
  // TECH
  [CareerType.TECH.id, [
    CareerTeam.DEVELOPMENT,
    CareerTeam.INFRASTRUCTURE,
    CareerTeam.PRODUCT_DESIGN,
    CareerTeam.DATA_ANALYTICS,
    CareerTeam.SUPPORT_SERVICES
  ]],

  // OPERATIONAL
  [CareerType.OPERATIONAL.id, [
    CareerTeam.FINANCE,
    CareerTeam.INTERNAL_AUDIT,
    CareerTeam.RISK,
    CareerTeam.CORPORATE_RISK,
    CareerTeam.MERCHANT_OPERATIONS,
    CareerTeam.HUMAN_RESOURCES,
    CareerTeam.SECURITY,
    CareerTeam.LEGAL,
    CareerTeam.COMPLIANCE,
    CareerTeam.COMMERCIAL_OPERATIONS,
    CareerTeam.COMMUNICATIONS,
    CareerTeam.TREASURY
  ]],

  // PRODUCT
  [CareerType.PRODUCT.id, [
    CareerTeam.PRODUCT_MANAGEMENT
  ]],

  // Commercial
  [CareerType.COMMERCIAL.id, [
    CareerTeam.ACCOUNT_MANAGEMENT,
    CareerTeam.SALES,
    CareerTeam.MARKETING,
    CareerTeam.PARTNERSHIPS,
    CareerTeam.COMMERCIAL,
    CareerTeam.PAYMENT_PARTNERSHIPS
  ]],

  // NEXT_GEN
  [CareerType.NEXTGEN.id, [
    CareerTeam.NEXTGEN,
    CareerTeam.PHD,
    CareerTeam.WORKING_STUDENTS,
    CareerTeam.INTERNSHIP,
    CareerTeam.STUDENTS_GRADUATES
  ]]

])

const OfficesGreenhouseMap = Object.fromEntries(Object.values(CareerOffice).map(off => ([off.id, off])))
const TeamsGreenhouseMap = Object.fromEntries(Object.values(CareerTeam).map(off => ([off.id, off])))

export const getOfficeForCity = (city) => {
  return CareerOffice[city] || CareerOffice.NOT_SET
}

export const getOfficeForGreenhouseId = (id, name?) => {
  return OfficesGreenhouseMap[id] || Object.values(CareerOffice).find(off => off.name === name) || CareerOffice.NOT_SET
}

export const getTeamForGreenhouseId = (id, name?) => {
  return TeamsGreenhouseMap[id] || Object.values(CareerTeam).find(off => off.name === name) || CareerTeam.NOT_SET
}

export const getTeamsForCareerType = (careerType) => {
  return CareerTypeMap.get(careerType)
}

export const getIconForCareerType = (careerType) => {
  return CareerType[careerType]?.logo
}

export const getCareerTypeForTeam = ({ id, name }) => {
  return getCareerTypeForTeamId(id) || getCareerTypeForTeamName(name)
}

export const getRelatedTeams = (teamId) => {
  const careerType = getCareerTypeForTeamId(teamId)
  return getTeamsForCareerType(careerType)?.filter(team => team.id !== teamId) || []
}

const getCareerTypeForTeamName = (teamName) => {
  return [...CareerTypeMap.keys()].find(key => CareerTypeMap.get(key)?.some(team => team.name === teamName))
}

const getCareerTypeForTeamId = (teamId) => {
  return [...CareerTypeMap.keys()].find(key => CareerTypeMap.get(key)?.some(team => team.id === teamId))
}
