import { gql } from 'graphql-tag'
import { fragmentModuleCompanyFormulaItem } from '../../modules/fragments'

export default gql`
  ${fragmentModuleCompanyFormulaItem}
  fragment BlockCompanyFormulaFragment on BlockCompanyFormula {
    sys {
      id
    }
    title
    description {
      json
    }
    itemsCollection {
      items {
        ...ModuleCompanyFormulaItemFragment
      }
    }
  }
`
