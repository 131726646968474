import { mapperModuleNavigationLink } from '../../../core/data-layer/modules/mappers'
import type { MapperOptions } from '@/types'

export default (data: any, options: MapperOptions) => {
  if (!data) {
    return null
  }

  return {
    socialShareLabel: data.socialShareLabel || '',
    backLink: mapperModuleNavigationLink(data.backLink, options.getUrl),
    relatedJobsLink: mapperModuleNavigationLink(data.relatedJobsLink, options.getUrl),
    companyFormulaLink: mapperModuleNavigationLink(data.companyFormulaLink, options.getUrl),
    companyFormulaTitle: data.companyFormula?.title || '',
    companyFormulaDescription: data.companyFormula?.description || ''
  }
}
