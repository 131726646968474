import { mapperModuleMediaCard } from '../../modules/module-media-card/mapper-module-media-card'
import { mapperModuleNavigationLink } from '../../modules/module-navigation-link/mapper-module-navigation-link'
import type { MapperOptions } from '../../../types'

/**
 * MediaCardGroup mapper
 */

const HORIZONTAL = 'landscape'

export const mapperBlockMediaCardGroup = (data: any, options: MapperOptions) => {
  if (!data) {
    return null
  }

  const moduleMediaItems = data.itemsCollection?.items?.map((item: any) => {
    return mapperModuleMediaCard(item, options)
  }) || []

  const itemsWidth = moduleMediaItems[0]?.orientation === HORIZONTAL
    ? { small: 272, large: 472 }
    : { small: 240, large: 264 }

  return {
    id: data?.sys?.id || '',
    title: data?.title || '',
    description: data?.mediaCardDescription || '',
    items: moduleMediaItems,
    ctaLink: mapperModuleNavigationLink(data?.ctaLink, options.getUrl),
    width: itemsWidth
  }
}
