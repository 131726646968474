export const usePageUtils = () => {
  /**
 * Get slug from route path
 * @param {String} path - route path
 * @return {String} slug to be used in query variables
 */
  const getSlug = (path) => {
    const pathParts = path.split('/').filter(Boolean)
    return pathParts[pathParts.length - 1]
  }

  /**
 * Get parent slug from route path
 * @param {String} path - route path
 * @return {String} slug to be used in query variables
 */
  const getSlugParent = (path) => {
    const pathParts = path.split('/').filter(Boolean)
    return pathParts[pathParts.length - 2]
  }

  /**
 * Get parent slug from route path
 * @param {String} path - route path
 * @return {String} slug to be used in query variables
 */
  const getParentPath = (path) => {
    const pathParts = path.split('/').filter(Boolean)
    pathParts.pop()
    return '/' + pathParts.join('/')
  }

  /**
 * @function getSortedPaymentMethods - Returns an array of related payment methods from online and in person channels
 * @param {Object[]} channelOnline - List of online payment methods
 * @param {Object[]} channelInPerson - List of in person payment methods
 * @returns {Object[]}
 */

  const getSortedPaymentMethods = (channelOnline, channelInPerson) => {
    const items = new Set()
    const maxLength = Math.max(channelOnline.length, channelInPerson.length)

    for (let i = 0; i < maxLength; i++) {
      if (channelOnline[i]) {
        items.add(channelOnline[i].paymentMethod?.sys?.id)
      }
      if (channelInPerson[i]) {
        items.add(channelInPerson[i].paymentMethod?.sys?.id)
      }
    }

    return Array.from(items)
  }

  /**
 * @function getRelatedPaymentMethods - Returns an array of related payment methods from online and in person channels
 * @param {Object[]} channelOnline - List of online payment methods
 * @param {Object[]} channelInPerson - List of in person payment methods
 * @param {Number} length - The length of the array that will be returned
 * @returns {Object[]}
 */
  const getRelatedPaymentMethods = (channelOnline, channelInPerson, length = 7) => {
    const paymentMethods = channelOnline.concat(channelInPerson)
    const relatedPaymentMethods = getSortedPaymentMethods(channelOnline, channelInPerson).splice(0, length)
    return relatedPaymentMethods.map(id => ({
      id,
      pageId: paymentMethods?.find(i => id === i?.paymentMethod?.sys?.id)?.sys?.id
    }))
  }

  /**
 * @function getBackgroundColor - Used to retrieve the payment methods' background colors. Returns a color hex code. If white is passed, returns Adyen's default light grey.
 * @param {String} color - The hex color code (including #)
 * @returns {string}
 */
  const getBackgroundColor = (color) => {
    return ((color && !['#ffffff', '#fff'].includes(color) ? color : '#d2dae1'))
  }

  return {
    getBackgroundColor,
    getRelatedPaymentMethods,
    getSortedPaymentMethods,
    getSlug,
    getSlugParent,
    getParentPath
  }
}
