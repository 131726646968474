import { gql } from 'graphql-tag'
import { fragmentModuleNavigationLink, fragmentModuleMedia } from '../../../../core/data-layer/modules/fragments'
import { fragmentGlobalDataTheme } from '../../../../core/data-layer/global-data/fragments'

export default gql`
    ${fragmentModuleNavigationLink}
    ${fragmentModuleMedia}
    ${fragmentGlobalDataTheme}
    fragment BlockCampaignsCallOutFragment on BlockCampaignsCallOut {
        title
        description {
            json
        }
        link {
            ...ModuleNavigationLinkFragment
        }
        image {
            ...ModuleMediaFragment
        }
        orientation
        theme {
            ...GlobalDataThemeFragment
        }
    }
`
