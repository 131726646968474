import { gql } from 'graphql-tag'

export default gql`
  fragment ModuleUspFragment on ModuleUsp {
     sys {
      id
    }
    title
    description {
      json
    }
    icon
  }
`
