import { gql } from 'graphql-tag'
import { fragmentModuleMedia } from '../../modules/fragments'

export default gql`
  ${fragmentModuleMedia}
  fragment GlobalDataArticleAuthorFragment on GlobalDataArticleAuthor {
    sys {
      id
    }
    internalName
    name
    companyName
    role
    image {
      ...ModuleMediaFragment
    }
  }
`
