import { gql } from 'graphql-tag'
import { fragmentModuleMedia, fragmentModuleInternalVideo, fragmentModuleVideo } from '../../../../core/data-layer/modules/fragments'
import { fragmentGlobalDataTheme } from '../../../../core/data-layer/global-data/fragments'

export default gql`
    ${fragmentModuleMedia}
    ${fragmentModuleVideo}
    ${fragmentModuleInternalVideo}
    ${fragmentGlobalDataTheme}
    fragment BlockCampaignsMediaFragment on BlockCampaignsMedia {
        contentType: __typename
        sys {
            id
        }
        title
        blockCampaignsMediaDescription: description
        media {
            ...on ModuleMedia {
                contentType: __typename
                ...ModuleMediaFragment
            }
            ...on ModuleVideo {
                contentType: __typename
                ...ModuleVideoFragment
            }
            ...on ModuleInternalVideo {
                contentType: __typename
                ...ModuleInternalVideoFragment
            }
        }
        sectionTheme {
            ...GlobalDataThemeFragment
        }
    }
`
