import { gql } from 'graphql-tag'
import { fragmentModuleSocialItem } from '../../modules/fragments'

export default gql`
  ${fragmentModuleSocialItem}
  fragment BlockSocialShareFragment on BlockSocialShare {
    sys {
      id
    }
    title
    itemsCollection(limit: 4) {
      items {
        sys {
          id
        }
        ...ModuleSocialItemFragment
      }
    }
  }
`
