import { gql } from 'graphql-tag'
import { fragmentGlobalDataArticleType } from '../../../../core/data-layer/global-data/fragments'

export default gql`
    ${fragmentGlobalDataArticleType}
    fragment BlockSimpleRelatedArticlesFragment on BlockSimpleRelatedArticles {
        sys {
            id
        }
        title
        itemsCollection(limit: 4) {
            items {
                sys {
                    id
                }
                title
                minutesToRead
                date
                articleType {
                    ...GlobalDataArticleTypeFragment
                }
            }
        }
    }
`
