import { upperFirst } from 'lodash-es'

export const toCollection = item => `${item}Collection`

export const toFragment = name => name ? `${upperFirst(name)}Fragment` : ''

export const toSpread = item => `...${item}`

export const toQueryBrackets = (item) => {
  return ` {
          ${item}
        }`
}
