import { gql } from 'graphql-tag'
import { fragmentModuleMedia, fragmentModuleNavigationLink, fragmentModuleVideo } from '../../../../core/data-layer/modules/fragments'

export default gql`
    ${fragmentModuleNavigationLink}
    ${fragmentModuleMedia}
    ${fragmentModuleVideo}
    fragment BlockCampaignsCardsItemFragment on BlockCampaignsCardsItem {
        title
        text
        link {
            ...ModuleNavigationLinkFragment
        }
        image {
            ...ModuleMediaFragment
        }
        video {
            ...ModuleVideoFragment
        }
    }
`
