import imgModule from 'adyen-website-com/constants/imageSizes'
// when the import happens in the server it is processed as a module, and we need to retrieve the .default
const imageSizes = imgModule?.default || imgModule

const PageInvestorRelationsTradingUpdates = {
  contentType: 'PageInvestorRelationsTradingUpdates',
  fields: [
    {
      id: 'backLink',
      name: 'Back link text',
      type: 'Symbol'
    },
    {
      id: 'sections',
      name: 'Trading updates sections',
      type: 'Array',
      subType: 'BlockTradingUpdate'
    }
  ]
}

const PageInvestorRelationsShortTradingUpdate = {
  contentType: 'PageInvestorRelationsShortTradingUpdate',
  fields: [
    {
      id: 'image',
      name: 'Image',
      type: 'ModuleMedia',
      imageOptions: { resize: true, sizes: imageSizes.columns12_5269, lazy: true }
    },
    {
      id: 'period',
      name: 'Period',
      type: 'Text'
    },
    {
      id: 'letter',
      name: 'Letter',
      type: 'ReportsContentBlock'
    },
    {
      id: 'financialStatementsTitle',
      name: 'Financial Statements Title',
      type: 'Text'
    },
    {
      id: 'financialStatementsPdf',
      name: 'Financial Statements PDF',
      type: 'ModuleMedia'
    },
    {
      id: 'notice',
      name: 'Notice',
      type: 'RichText'
    }
  ]
}

const PageInvestorRelationsGeneralMeetings = {
  contentType: 'PageInvestorRelationsGeneralMeetings',
  fields: [
    {
      id: 'sectionTitlePublications',
      name: 'Section Title Publications',
      type: 'Symbol'
    },
    {
      id: 'titleAnnualMeetings',
      name: 'Title Annual Meetings',
      type: 'Symbol'
    },
    {
      id: 'itemsAnnualMeetings',
      name: 'Items Annual Meetings',
      type: 'Array',
      subType: 'FinancialsItem' // @TODO create mapper
    },
    {
      id: 'noteAnnualMeetings',
      name: 'Note Annual Meetings',
      type: 'RichText'
    },
    {
      id: 'titleExtraordinaryMeetings',
      name: 'Title Extraordinary Meetings',
      type: 'Symbol'
    },
    {
      id: 'itemsExtraordinaryMeetings',
      name: 'Items Extraordinary Meetings',
      type: 'Array',
      subType: 'FinancialsItem' // @TODO create mapper
    },
    {
      id: 'noteExtraordinaryMeetings',
      name: 'Note Extraordinary Meetings',
      type: 'RichText'
    }
  ]
}

export default {
  PageInvestorRelationsShortTradingUpdate,
  PageInvestorRelationsTradingUpdates,
  PageInvestorRelationsGeneralMeetings
}
