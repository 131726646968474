import { gql } from 'graphql-tag'
import { fragmentModuleMedia } from '../../modules/fragments'

export default gql`
  ${fragmentModuleMedia}
  fragment GlobalDataOfficeFragment on GlobalDataOffice {
    sys {
      id
    }
    name
    city {
      sys {
        id
      }
      name
      image {
        ...ModuleMediaFragment
      }
      country {
        sys {
          id
        }
        name
        region {
          sys {
            id
          }
          internalName
          uniqueId
          name
        }
      }
    }
    image {
      ...ModuleMediaFragment
    }
    addressLine1
    addressLine2
    phoneNumber
    addressLink
  }
`
