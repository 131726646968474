import { gql } from 'graphql-tag'
import fragmentModuleMedia from '../module-media/fragment-module-media'

export default gql`
  ${fragmentModuleMedia}
  fragment ModuleVideoFragment on ModuleVideo {
    contentType: __typename
    sys {
      id
    }
    title
    source
    caption
    thumbnail {
      ...ModuleMediaFragment
    }
    videoExternalLink: externalLink
    videoInternalLink: internalLink
  }
`
