import { mapperModuleMedia } from '../../../core/data-layer/modules/mappers.js'
import imageSizes from '../../../core/constants/imageSizes.js'

export default (data: any) => {
  if (!data) {
    return null
  }

  return {
    headingTitle: data.headingTitle || '',
    headingMedia: mapperModuleMedia(data.headingMedia, { resize: true, sizes: imageSizes.columns12_5269 })
  }
}
