import { gql } from 'graphql-tag'
import { fragmentModuleMedia, fragmentModuleNavigationLink } from '../../modules/fragments'

export default gql`
  ${fragmentModuleMedia}
  ${fragmentModuleNavigationLink}
  fragment BlockQuoteFragment on BlockQuote {
    sys {
      id
    }
    quote
    role
    tag
    author
    stars
    link {
      ...ModuleNavigationLinkFragment
    }
    image {
      ...ModuleMediaFragment
    }
     logo {
      ...ModuleMediaFragment
    }
  }
`
