import mapperIcon from '../../utils/mappers/mapper-icon'
import { mapperModuleMedia } from '../module-media/mapper-module-media'
import { mapperModuleVideo } from '../module-video/mapper-module-video'
import { mapperModuleNavigationLink } from '../module-navigation-link/mapper-module-navigation-link'
import type { MapperOptions } from '../../../types'

/**
 * Module media card mapper
 */
export const mapperModuleMediaCard = (data: any, options: MapperOptions) => {
  if (!data) {
    return null
  }

  return {
    id: data?.sys?.id || '',
    image: mapperModuleMedia(data.image, options.imageOptions),
    video: mapperModuleVideo(data.video, options.imageOptions),
    icon: mapperIcon(data.icon),
    orientation: data?.image?.src?.[0]?.orientation || '',
    title: data?.title || '',
    description: data?.cardDescription || '',
    link: mapperModuleNavigationLink(data.link, options.getUrl)
  }
}
