import { gql } from 'graphql-tag'

export default gql`
  fragment ModuleInternalVideoFragment on ModuleInternalVideo {
    contentType: __typename
    sys {
      id
    }
    title
    source
    caption
    thumbnailUrl
    frontifyAsset
  }
`
